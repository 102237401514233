import React, {useEffect, useState} from 'react'
import { Box } from 'grommet'
import {MarketSelect} from "../../components/market-select";
import {WidgetsBox} from "../../components";
import {PortfolioHistoryChart} from "./history-chart";
import {BottomPanel} from "./bottom-panel";
import {BadgesPanel} from "./badges-panel";
import {ChartContainerHeight, LeftColumnWidth} from "./constants";
import {useFutureAlias} from "../../hooks/useFutureAlias";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {MarketInfo} from "../../types";
import {PortfolioProps} from "./common";
import {useMarketsWithdrawableMargin} from "../../hooks/useWithdrawableMargin";
import {useNavigate, useParams} from "react-router-dom";
import {getMarketAlias} from "../../utils";

const gap = '16px'

export const PortfolioPage = () => {
  const { markets, portfolio } = useProtocolData()
  const futureData = useFutureAlias(markets)
  const navigate = useNavigate();
  const { marketAlias } = useParams()

  const {
    marketsWithdrawableMargin
  } = useMarketsWithdrawableMargin(markets.map(item => item.descriptor.id))
  const [selectedMarket, setSelectedMarket] = useState<MarketInfo | 'all'>('all')
  const [isDropdownOpened, setDropdownOpened] = useState(false)

  useEffect(() => {
    if(marketAlias) {
      if(marketAlias !== 'all') {
        for (const market of markets) {
          const alias = getMarketAlias(market)
          if (marketAlias === alias) {
            setSelectedMarket(market)
            return
          }
        }
      }
      setSelectedMarket('all')
    } else {
      navigate('/portfolio/all')
    }
  }, [marketAlias, markets.length]);

  // Set initial market
  // useEffect(() => {
  //   if(!selectedMarket && futureData?.market) {
  //     setSelectedMarket(futureData.market)
  //   }
  // }, [futureData, selectedMarket]);

  const commonProps: PortfolioProps = {
    market: selectedMarket,
    isMarketSelected: selectedMarket && selectedMarket !== 'all',
    portfolio,
    marketPortfolio: selectedMarket !== 'all'
      ? portfolio.find(item => item.descriptor.id === selectedMarket.descriptor.id)
      : undefined,
    marketsWithdrawableMargin
  }

  return <Box margin={{ bottom: '16px' }} style={{ minWidth: '900px' }}>
    <Box
      margin={{ top: gap }}
      width={'calc(100vw - 32px)'}
      justify={'between'}
      direction={'row'}
      gap={gap}
    >
      <Box width={LeftColumnWidth} gap={'16px'}>
        <MarketSelect
          viewType={'default'}
          mode={'markets'}
          width={'547.5px'}
          market={selectedMarket}
          future={futureData?.future}
          onDropdownChange={setDropdownOpened}
          onSelect={(market: MarketInfo | 'all') => {
            navigate(`/portfolio/${market === 'all' ? 'all' : getMarketAlias(market)}`)
          }}
        />
        <WidgetsBox overlay={isDropdownOpened}>
          <BadgesPanel {...commonProps} />
        </WidgetsBox>
      </Box>
      <WidgetsBox
        width={`calc(100% - ${LeftColumnWidth})`}
        height={ChartContainerHeight}
        overlay={isDropdownOpened}
      >
        <PortfolioHistoryChart {...commonProps} />
      </WidgetsBox>
    </Box>
    <WidgetsBox
      margin={{ top: gap }}
      overlay={isDropdownOpened}
      style={{ minHeight: `calc(100vh - ${ChartContainerHeight} - 110px)` }}
    >
      <BottomPanel {...commonProps} />
    </WidgetsBox>
  </Box>
}
