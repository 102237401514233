import {ethers} from "ethers";
import erc20MockABI from "../abi/erc20MockABI.json";
import config from "../config";
import RouterABI from "../abi/RouterABI.json";
import {arbitrum} from "wagmi/chains";
import {getSquidRouteQuote, SquidRouterQuote} from "../api/squid";
import {useEffect, useRef, useState} from "react";

const erc20Interface = new ethers.utils.Interface(erc20MockABI);

export const useSquidRoute = (params: {
  userAddress?: string,
  marketId: string
  fromChain: number,
  fromToken?: string
  fromAmount: string,
  toToken?: string,
  enabled?: boolean
}) => {
  const {
    userAddress,
    marketId,
    fromChain,
    fromToken,
    fromAmount,
    toToken,
    enabled = true
  } = params

  const [isLoading, setIsLoading] = useState(false)
  const [squidQuote, setSquidQuote] = useState<SquidRouterQuote>()
  const [abortController, setAbortController] = useState<AbortController>()

  const loadRouteData = async (signal: AbortSignal) => {
    if(
      !params.userAddress
      || !params.fromToken
      || !params.toToken
      || (!params.fromAmount || params.fromAmount === '0')
    ) {
      setIsLoading(false)
      setSquidQuote(undefined)
      return
    }

    console.log('Fetching squid route...', params)

    setIsLoading(true)
    try {
      const approveCallArgs = [
        config.routerContractAddress,
        "0", // Placeholder for dynamic balance
      ]

      const approveEncodedData = erc20Interface.encodeFunctionData(
        'approve',
        approveCallArgs
      );

      const approveCall = {
        chainType: "evm",
        callType: 1, // SquidCallType.FULL_TOKEN_BALANCE
        target: toToken,
        value: '0',
        callData: approveEncodedData,
        payload: {
          tokenAddress: toToken,
          inputPos: 1,
        },
        estimatedGas: '20000',
      }

      const depositCallArgs = [
        marketId,
        userAddress,
        "0", // Placeholder for dynamic balance
        true,
        [],
      ]
      const routerContractInterface = new ethers.utils.Interface(RouterABI);
      const depositEncodedData = routerContractInterface.encodeFunctionData(
        'deposit',
        depositCallArgs
      );

      // 0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE - ETH
      // 0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9 - USDT

      const depositCall = {
        chainType: "evm",
        callType: 1, // SquidCallType.FULL_TOKEN_BALANCE
        target: config.routerContractAddress,
        value: '0',
        callData: depositEncodedData,
        payload: {
          tokenAddress: toToken,
          inputPos: 2,
        },
        estimatedGas: '600000',
      }

      const postHook = {
        provider: 'Rho Protocol',
        description: "Deposit collateral",
        logoURI: 'https://radiant.svg',
        chainType: "evm",
        calls: [
          approveCall,
          depositCall
        ]
      }

      const params = {
        fromChain: fromChain.toString(),
        toChain: arbitrum.id.toString(),
        fromToken,
        toToken,
        fromAmount,
        fromAddress: userAddress,
        toAddress: userAddress,
        slippage: 1,
        quoteOnly: false,
        enableBoost: true,
        postHook: postHook,
      }
      const result = await getSquidRouteQuote(params, signal);
      console.log('Fetched squid route:', result)
      setSquidQuote(result)
    } catch(e) {
      console.error('Failed to build squid route:', e)
      setSquidQuote(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if(abortController) {
      abortController.abort('New request')
    }

    const controller = new AbortController();
    const signal = controller.signal;

    if(enabled) {
      setAbortController(controller)
      loadRouteData(signal)
    }

    return () => {controller.abort();};
  }, [
    params.userAddress,
    params.marketId,
    params.fromToken,
    params.toToken,
    params.fromChain,
    params.fromAmount,
    enabled
  ]);

  return {
    isLoading,
    squidQuote,
  }
}
