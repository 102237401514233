import React from 'react'
import {Box, Text} from "grommet";
import {Skeleton, Typography} from "antd";
import {useAccount} from "wagmi";
import {bnToDecimal, formatNumber, truncateEthAddress} from "../../utils";
import {EarnWidget, TableRow, VaultProps} from "./common";

const height = '100%'

export const VaultInfoPage = (props: VaultProps) => {
  const { vault } = props

  const vaultId = vault
    ? <Typography.Text copyable={{ text: vault.address }} style={{ fontSize: '14px', color: '#E4E4E8' }}>
      {vault.id}
    </Typography.Text>
    : <Skeleton.Input active size={'small'} style={{ width: '240px', height }} />

  const vaultAddress = vault
    ? <Typography.Text copyable={{ text: vault.address }} style={{ fontSize: '14px', color: '#E4E4E8' }}>
        {vault.address}
      </Typography.Text>
    : <Skeleton.Input active size={'small'} style={{ width: '240px', height }} />

  const underlyingAddress = vault
    ? <Typography.Text copyable={{ text: vault.key.underlying }} style={{ fontSize: '14px', color: '#E4E4E8' }}>
      {vault.key.underlying}
    </Typography.Text>
    : <Skeleton.Input active size={'small'} style={{ width: '240px', height }} />

  const name = vault
    ? <Typography.Text style={{ fontSize: '14px', color: '#E4E4E8', height }}>
      {vault.key.name}
    </Typography.Text>
    : <Skeleton.Input active size={'small'} style={{ width: '240px', height }} />

  const version = vault
    ? <Typography.Text style={{ fontSize: '14px', color: '#E4E4E8', height }}>
      {vault.key.version}
    </Typography.Text>
    : <Skeleton.Input active size={'small'} style={{ width: '240px', height }} />

  return <Box gap={'16px'}>
    <Text color={'textSecondary'} size={'16px'}>Vault info</Text>
    <EarnWidget style={{ padding: '16px 0' }}>
      <TableRow items={['Name', name]} />
      {/*<TableRow items={['Id', vaultId]} />*/}
      <TableRow items={['Address', vaultAddress]} />
      <TableRow items={['Asset', underlyingAddress]} />
      {/*<TableRow items={['Version', version]} />*/}
    </EarnWidget>
  </Box>
}
