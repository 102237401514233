import React, {useMemo, useRef, useState} from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Button, Spinner} from "grommet";
import {QuestionMark} from "../QuestionMark";
import {Arrow, MarketIcon} from "../Icon";
import {CoinName} from "../Coin";
import styled from "styled-components";
import {MarketInfo, MarketPortfolio} from "../../types";
import {WidgetContainer} from "../widgets";
import {marginTotal} from "../../utils/mappers";
import {Number} from "../Number";
import {abbreviateNumber, bnToDecimal, getFutureAlias} from "../../utils";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {PageModal} from "../../constants";
import {useNavigate} from "react-router-dom";
import {BigNumber} from "ethers";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {useFutureAlias} from "../../hooks/useFutureAlias";
import {useTokenPrice} from "../../providers/PriceProvider";
import {useMediaQuery} from "react-responsive";

const SecondaryButton = styled(Button)`
    background: #2E2E38;
    padding: 3px 12px;
`

const TableContainer = styled(Table)`
    background: #1E1E20;
    border: 1px solid #2D2E43;
    border-radius: 4px;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    padding-bottom: 5px;
    
  thead {
    th {
      color: #838495;
      font-size: 14px;
      font-weight: 400;
      border: none;
      padding: 12px;
    }
  }
  
  tbody {
    tr {
      transition: background-color 0.2s;
    }
      
    tr {
        //background: #323441;
        background: #1E1E20;
    }
    
    //tr:hover {
    //  background: #323441;
    //}
    
    //tr:nth-child(2n + 1):not(:hover) {
    //  background: #242429;
    //}
  }

  td:first-child {
    padding: 0;
  }

  td:last-child {
    padding: 6px 12px;
    text-align: right;
  }
`

const TableMarkerCell = styled(TableCell)<{ isActive: boolean }>`
    padding-left: 14px !important;
    ${props => props.isActive &&
    `
        padding-left: 11px !important;
        border-left: 3px solid ${props.theme.global.colors.brandRho};
      `
}
`

const AccountMarginTable = (props: {
  markets: MarketInfo[]
  portfolio: MarketPortfolio[]
  isFetching: boolean
}) => {
  const { markets, portfolio, isFetching } = props
  const navigate = useNavigate()
  const isIpadPro = useMediaQuery({ query: '(max-width: 1400px)' })

  const futureData = useFutureAlias(markets)

  const onOpenManageMargin = (marketId: string, type: 'deposit' | 'withdraw') => {
    navigate(`?modal=${PageModal.margin}&type=${type}&marketId=${marketId}`)
  }

  if(isFetching) {
    return <WidgetContainer direction={'row'} align={'center'} gap={'12px'}>
      <Spinner color={'spinner'} />
      Loading...
    </WidgetContainer>
  }

  return <Box>
    <TableContainer>
      <TableHeader>
        <TableRow>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text color={'textSecondary'}>MARKET</Text>
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text color={'textSecondary'}>BALANCE</Text>
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text color={'textSecondary'}>CURRENCY</Text>
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} justify={'end'} gap={'2px'}>
              <Text color={'textSecondary'}>ACTION</Text>
            </Box>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {markets.map((market) => {
          const portfolioItem = portfolio.find((item) => item.descriptor.id === market.descriptor.id)
          const total = portfolioItem ? marginTotal(portfolioItem.marginState.margin) : BigNumber.from(0)

          const isActive = (futureData && futureData.future.marketId === market.descriptor.id) || false;

          const onMarketClick = () => {
            if (market && market.futures.length > 0 && !isActive) {
              const rowFutureAlias = getFutureAlias(market, market.futures[0])
              navigate(`/trade/${rowFutureAlias}`)
            }
          }

          return <TableRow key={market.descriptor.id}>
            <TableMarkerCell
                width={'160px'} isActive={isActive}
                onClick={isActive ? undefined : onMarketClick}
                style={{ cursor: isActive ? 'default' : 'pointer' }}
            >
              <Box direction={'row'} align={'center'} gap={'6px'}>
                <Box width={'22px'} style={{ minWidth: '22px' }}>
                  <MarketIcon name={market.descriptor.sourceName} size={'22px'} iconSize={'12px'} />
                </Box>
                <Text size={'12px'}>
                  {market.descriptor.sourceName} {market.descriptor.instrumentName}
                </Text>
              </Box>
            </TableMarkerCell>
            <TableCell>
              <Text size={'12px'}>
                <Number
                  value={total}
                  decimals={market.descriptor.underlyingDecimals}
                  name={market.descriptor.underlyingName}
                />
              </Text>
            </TableCell>
            <TableCell>
              <Box direction={'row'}>
                <CoinName name={market.descriptor.underlyingName} color={'text'} />
              </Box>
            </TableCell>
            <TableCell>
              <Box direction={'row'} gap={'10px'}>
                <SecondaryButton onClick={() => onOpenManageMargin(market.descriptor.id, 'deposit')}>
                  <Text color={'accentWhite2'} weight={400}>Deposit</Text>
                </SecondaryButton>
                <SecondaryButton onClick={() => {
                  navigate(`?modal=${PageModal.transfer}&marketId=${market.descriptor.id}`)
                }}>
                  <Text color={'accentWhite2'} weight={400}>Transfer</Text>
                </SecondaryButton>
                <SecondaryButton onClick={() => onOpenManageMargin(market.descriptor.id, 'withdraw')}>
                  <Text color={'accentWhite2'} weight={400}>Withdraw</Text>
                </SecondaryButton>
              </Box>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </TableContainer>
  </Box>
}

const BoxWrapper = styled(Box)<{ isActive?: boolean }>`
    background: #1F1F23;
    border: 1px solid #2D2E43;
    border-radius: 4px;
    transition: border-bottom-color 200ms, border-top-color 200ms, border-left-color 200ms, border-right-color 200ms;
    &:hover {
        border-color: #8186E4;
    }

    ${props => props.isActive && `
      border-color: #8186E4;
    `}
`

export const AccountMargin = () => {
  const {
    markets,
    portfolio,
    portfolioInitialFetching
  } = useProtocolData()
  const isIpadPro = useMediaQuery({ query: '(max-width: 1400px)' })

  const futureData = useFutureAlias(markets)
  const { ethereum: ethPrice, tetherPrice } = useTokenPrice()

  const { totalMargin, currentMarketMargin } = useMemo(() => {
    let totalMargin = 0
    let currentMarketMargin = 0

    if(ethPrice && tetherPrice) {
      const marketPortfolio = portfolio.find(item => item.descriptor.id === futureData?.market.descriptor.id)

      totalMargin = portfolio.reduce((acc, portfolioItem) => {
        const { descriptor: { id: marketId, underlyingName: name, underlyingDecimals } } = portfolioItem
        const margin = marginTotal(portfolioItem.marginState.margin)

        let tokenPrice = 0
        if(ethPrice && name.toLowerCase().endsWith('eth')) {
          tokenPrice = ethPrice
        } else if(tetherPrice && name.toLowerCase().includes('usd')) {
          tokenPrice = tetherPrice
        }
        const marketMargin = tokenPrice * bnToDecimal(margin, underlyingDecimals).toNumber()

        if(marketPortfolio && marketId === marketPortfolio.descriptor.id) {
          currentMarketMargin = marketMargin
        }

        return acc += marketMargin
      }, 0)
    }
    return {
      totalMargin,
      currentMarketMargin
    }
  }, [portfolio, futureData, ethPrice, tetherPrice])

  const ref = useRef<any>();
  const [isDropdownOpened, setDropdownOpened] = useState(false)
  useOnClickOutside(ref, (event: MouseEvent | TouchEvent) => {
    try {
      if(event.target) {
        const modal = document.getElementsByClassName('antd_modal_container')
        const modalWrapper = document.getElementsByClassName('ant-modal-wrap')
        if([modal[0], modalWrapper[0]].find(item => item.contains(event.target as Node))) {
          return
        }
      }
    } catch (e) {}
    setDropdownOpened(false)
  });

  return <Box ref={ref}>
    <BoxWrapper
      pad={'3px 12px 3px 8px'}
      isActive={isDropdownOpened}
      onClick={() => setDropdownOpened(!isDropdownOpened)}
    >
      <Box direction={'row'} align={'center'} gap={'16px'} justify={'between'}>
        {!isIpadPro &&
            <Box direction={'row'} align={'center'} gap={'4px'}>
                <Text color={'textSecondary'}>Account value</Text>
                <QuestionMark tooltipId={'header_acc_value'} tooltipText={'Current market margin (USD) / Total margin (USD)'} />
            </Box>
        }
        <Box direction={'row'} align={'center'} gap={'6px'} justify={'end'} style={{ minWidth: '100px' }}>
          <Box direction={'row'} gap={'4px'}>
            <Text>{'$'}{abbreviateNumber(currentMarketMargin, 1, 3)}</Text>
            <Text>{'/'}</Text>
            <Text>{'$'}{abbreviateNumber(totalMargin)}</Text>
          </Box>
          <Arrow width={'10px'} direction={isDropdownOpened ? 'up' : 'down'} />
        </Box>
      </Box>
    </BoxWrapper>
    <Box style={{ position: 'relative' }}>
      <Box
        style={{
          position: 'absolute',
          left: '0px',
          top: '8px',
          zIndex: 5,
          display: isDropdownOpened ? 'block' : 'none'
        }}
      >
        <AccountMarginTable
          isFetching={portfolioInitialFetching}
          markets={markets}
          portfolio={portfolio}
        />
      </Box>
    </Box>
  </Box>
}
