import React, {useMemo} from 'react'
import {Box, Text} from "grommet";
import {MarketInfo} from "../../types";
import {EventType, TradeType, TradeTypeAlias} from "./common";
import styled from "styled-components";
import moment from "moment";
import {Checkbox, MenuProps, Select, Switch} from "antd";
import {PrimaryButton} from "../../components";
import {useAccount} from "wagmi";

export interface HistoryFiltersProps {
  isLoading: boolean
  tradeType: TradeType
  setTradeType: (type: TradeType) => void
  isExportLoading: boolean
  markets: MarketInfo[]
  filterEventType: EventType
  setFilterEventType: (eventType: EventType) => void
  filterMarkets: string[]
  setFilterMarkets: (marketIds: string[]) => void
  filterMaturity: string[]
  setFilterMaturity: (futureIds: string[]) => void
  filterTimestamp: number[]
  setFilterTimestamp: (timestamps: number[]) => void
  onExportClicked: () => void
}

const FilterButtonContainer = styled(Box)<{ type: string }>`
  color: #C6C7EC;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: 500;
  user-select: none;

  ${props => props.type !== 'primary' && `
      &:hover {
        background-color: #20242E;
      }
  `}

  ${props => props.type === 'primary' && `
    background-color: #373E62;
  `}
`

const FiltersButton = (props: { children: any; type?: string; onClick?: () => void }) => {
  return <FilterButtonContainer
    type={props.type || 'default'}
    onClick={props.onClick}
  >
    {props.children}
  </FilterButtonContainer>
}

export const HistoryFilters = (props: HistoryFiltersProps) => {
  const {
    markets,
    filterEventType,
    setFilterEventType,
    filterMarkets,
    setFilterMarkets,
    filterMaturity,
    setFilterMaturity,
    isExportLoading
  } = props

  const { isConnected } = useAccount()

  const futuresList = useMemo(() => {
    return markets.map(item => item.futures).flat()
  }, [markets])

  const onMarketItemClick = (marketId: string, isSelected: boolean) => {
    const ids = markets
      .filter(market => {
        if(market.descriptor.id === marketId) {
          return isSelected
        }
        return filterMarkets.find(id => id === market.descriptor.id)
      })
      .map(market => market.descriptor.id)
    setFilterMarkets(ids)
  }

  const marketItems: MenuProps['items'] = markets.map((item => {
    const isSelected = filterMarkets.includes(item.descriptor.id)
    return {
      key: item.descriptor.id,
      label: <Box
        direction={'row'}
        gap={'8px'}
        onClick={() => onMarketItemClick(item.descriptor.id, !isSelected)}
      >
        <Checkbox checked={isSelected} />
        <Text>
          {item.descriptor.sourceName} {item.descriptor.instrumentName}
        </Text>
      </Box>,
    }
  }))

  marketItems.push({
    type: 'divider',
  }, {
    key: 'select_all',
    label: 'Select / Unselect All',
    onClick: () => {
      if(filterMarkets.length === markets.length) {
        setFilterMarkets([])
      } else {
        setFilterMarkets(markets.map(item => item.descriptor.id))
      }
    }
  })

  const onMaturityItemClick = (futureId: string, isSelected: boolean) => {
    const futureIds = futuresList
      .filter(futureData => {
        if(futureData.id === futureId) {
          return isSelected
        }
        return filterMaturity.find(id => id === futureData.id)
      })
      .map(futureData => futureData.id)
    setFilterMaturity(futureIds)
  }

  const maturityItems: MenuProps['items'] = futuresList
    .map(future => {
      const { id: futureId, marketId, termStart, termLength } = future
      const maturityTimestamp = termStart.add(termLength).toNumber() * 1000
      const maturityDate = moment(maturityTimestamp).format('DDMMMYY')
      const isSelected = filterMaturity.includes(futureId)

      const market = markets.find(market => market.descriptor.id === marketId)
      return {
        key: futureId,
        label: <Box
          direction={'row'}
          gap={'8px'}
          onClick={() => onMaturityItemClick(futureId, !isSelected)}
        >
          <Checkbox checked={isSelected} />
          <Box>
            <Text>{market?.descriptor.sourceName} {market?.descriptor.instrumentName} - {maturityDate}</Text>
          </Box>
        </Box>
      }
    })

  maturityItems.push({
    type: 'divider',
  }, {
    key: 'select_all',
    label: 'Select / Unselect All',
    onClick: (e) => {
      if(filterMaturity.length === futuresList.length) {
        setFilterMaturity([])
      } else {
        setFilterMaturity(futuresList.map(futureData => futureData.id))
      }
    }
  })

  return <Box direction={'row'} justify={'between'}>
    <Box direction={'row'} gap={'16px'} align={'center'}>
      <Box direction={'row'} gap={'8px'}>
        <FiltersButton
          type={filterEventType === EventType.tradeUpdate ? 'primary' : 'text'}
          onClick={() => setFilterEventType(EventType.tradeUpdate)}
        >Trades
        </FiltersButton>
        <FiltersButton
          type={filterEventType === EventType.liquidityUpdate ? 'primary' : 'text'}
          onClick={() => setFilterEventType(EventType.liquidityUpdate)}
        >Liquidity Provision</FiltersButton>
        <FiltersButton
          type={filterEventType === EventType.marginUpdate ? 'primary' : 'text'}
          onClick={() => setFilterEventType(EventType.marginUpdate)}
        >Margin transfers
        </FiltersButton>
      </Box>
      <Box margin={{ left: '32px' }}>
        {/*<Box width={'200px'} direction={'row'} gap={'4px'}>*/}
        {/*  <Text onClick={toggleSwitch} style={{ cursor: 'pointer' }}>Include maker trades</Text>*/}
        {/*  <Switch value={props.isShowMakerTrades} onClick={toggleSwitch} />*/}
        {/*</Box>*/}
        {/*{props.filterEventType === 'trades' &&*/}
        {/*    <Box width={'200px'}>*/}
        {/*        <Select value={TradeTypeAlias[props.tradeType]} options={Object.keys(TradeType).map(type => {*/}
        {/*          return {*/}
        {/*            value: type,*/}
        {/*            label: TradeTypeAlias[type as TradeType]*/}
        {/*          }*/}
        {/*        })} onChange={(item) => {*/}
        {/*          props.setTradeType(item as TradeType)*/}
        {/*        }} />*/}
        {/*    </Box>*/}
        {/*}*/}
      </Box>
    </Box>
    <Box direction={'row'} gap={'32px'}>
      {/*<Box direction={'row'} gap={'8px'}>*/}
      {/*  <Select*/}
      {/*    placeholder={<Text*/}
      {/*      weight={500}*/}
      {/*      color={filterMarkets.length < markets.length ? '#4852FF' : 'unset'}*/}
      {/*    >*/}
      {/*      Markets*/}
      {/*  </Text>}*/}
      {/*    items={marketItems}*/}
      {/*    onUpdate={setFilterMarkets}*/}
      {/*  />*/}
      {/*  {[EventType.tradeUpdate, EventType.liquidityUpdate].includes(filterEventType) &&*/}
      {/*      <Select*/}
      {/*          placeholder={<Text*/}
      {/*            weight={500}*/}
      {/*            color={filterMaturity.length < futuresList.length ? '#4852FF' : 'unset'}*/}
      {/*          >*/}
      {/*            Maturity*/}
      {/*      </Text>}*/}
      {/*          items={maturityItems}*/}
      {/*          onUpdate={setFilterMaturity}*/}
      {/*      />*/}
      {/*  }*/}
      {/*  <SelectDate*/}
      {/*    filterName={'select_transaction_date'}*/}
      {/*    placeholder={<Text weight={500}>Date</Text>}*/}
      {/*    values={filterTimestamp}*/}
      {/*    icon={getIconByName('calendar').icon}*/}
      {/*    onUpdate={setFilterTimestamp}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box>
        <PrimaryButton
          text={isExportLoading ? 'Exporting...' : 'Export to CSV'}
          disabled={!isConnected || isExportLoading}
          loading={isExportLoading}
          size={'14px'}
          style={{ height: '38px', minHeight: '38px', padding: 'unset' }}
          onClick={props.onExportClicked}
        />
      </Box>
    </Box>
  </Box>
}
