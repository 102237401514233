import React from 'react'
import {Anchor, Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import {MarketInfo} from "../../types";
import styled from "styled-components";
import {Number} from "../../components/Number";
import {BigNumber} from "ethers";
import {CoinName} from "../../components/Coin";
import moment from "moment";
import {getIconByName} from "../../components";
import {getExplorerUrl} from "../../utils";
import {fromBn} from "evm-bn";
import bn from "bignumber.js";
import {FutureData} from "./common";
import {Provision} from "../../api/dataService";

const TableContainer = styled(Table)`
  thead {
    th {
      color: #858CAD;
      font-weight: 600;
      border: none;
    }
  }

  border-collapse: separate;
  border-spacing: 0;
  
  tbody {
    tr:nth-child(2n + 1) {
      background-color: #191D28;
      border-radius: 10px;
    }
  }

  //th:first-child, td:first-child {
  //  padding-left: 24px;
  //}
`

export interface LiquidityEventsProps {
  walletAddress?: `0x${string}`
  markets: MarketInfo[]
  events: Provision[]
}

interface EventRowProps {
  walletAddress?: `0x${string}`
  event: Provision;
  market: MarketInfo | undefined;
}

const LiquidityEventRow = (props: EventRowProps) => {
  const { walletAddress, market, event  } = props

  let eventName = +event.notionalAmount > 0
    ? <Text>Liquidity Provision</Text>
    : <Text>Liquidity Removal</Text>

  // if(event.owner !== event.initiator) {
  //   if(event.initiator === walletAddress) {
  //     eventName = <Text>Liquidation (Position unwind)</Text>
  //   } else {
  //     // eventName = <Text>Liquidation</Text>
  //   }
  // }

  const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
  const underlyingName = market?.descriptor.underlyingName || 'N/A'

  const blockTimestampMoment = moment(+event.timestamp * 1000)
  const txUrl = `${getExplorerUrl()}/tx/${event.txHash}`

  return <TableRow key={event.txHash}>
    <TableCell>{eventName}</TableCell>
    <TableCell>{market?.descriptor.sourceName} {market?.descriptor.instrumentName}</TableCell>
    <TableCell>
      <Box>
        <Text>
          {(moment(event.maturity * 1000).format('DD/MM/YYYY'))}
        </Text>
      </Box>
    </TableCell>
    <TableCell>
      <Box direction={'row'} gap={'2px'}>
        <Number
          value={BigNumber.from(event.notionalAmount)}
          decimals={underlyingDecimals}
          name={underlyingName}
        />
        <CoinName name={underlyingName} />
      </Box>
    </TableCell>
    <TableCell>
      <Text>
        {bn(fromBn(BigNumber.from(event.lowerBoundRate), 16)).toFormat(1)}%
        -
        {bn(fromBn(BigNumber.from(event.upperBoundRate), 16)).toFormat(1)}%
      </Text>
    </TableCell>
    <TableCell width={'60px'}>
      <Box>
        <Box>
          <Text>{blockTimestampMoment.format('h:mm a')}</Text>
        </Box>
        <Box>
          <Text style={{ opacity: 0.4 }}>
            {blockTimestampMoment.format('DD/MM/YYYY')}
          </Text>
        </Box>
      </Box>
    </TableCell>
    <TableCell width={'32px'}>
      <Anchor href={txUrl} target={'_blank'}>
        <Box>
          {getIconByName('external').icon}
        </Box>
      </Anchor>
    </TableCell>
  </TableRow>
}

export const LiquidityEvents = (props: LiquidityEventsProps) => {
  return <TableContainer>
    <TableHeader style={{ height: '35px' }}>
      <TableRow>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>EVENT</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>MARKET</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>MATURITY</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>NOTIONAL</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>RATE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>DATE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>EXPLORER</Text>
          </Box>
        </TableCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {props.events.map((event, index) => {
        const market = props.markets
          .find(market => market.descriptor.id === event.marketId)
        return <LiquidityEventRow
          key={event.txHash}
          walletAddress={props.walletAddress}
          market={market}
          event={event}
        />
      })}
    </TableBody>
  </TableContainer>
}
