import React from "react";
import {useNavigate} from "react-router-dom";
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import {Number, QuestionMark} from "../../../../components";
import tooltips from "../../../../utils/tooltips";
import bn from "bignumber.js";
import {BigNumber} from "ethers";
import {fromBn} from "evm-bn";
import styled from "styled-components";
import {LiquidityProvisionSummary} from "../../common";
import {useProtocolData} from "../../../../providers/ProtocolDataProvider";
import {bnToDecimal, getFutureAlias, getShortDate} from "../../../../utils";
import Decimal from 'decimal.js'
import {SelectMarket} from "../../../../components/market-select";

const TableContainer = styled(Table)`
    thead {
      th {
        color: #838495;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    tr {
      border-top: 0!important;
    }

    //td:first-child, th:first-child {
    //  border-top: 0 !important;
    //  padding: 0;
    //}

    td {
        border-top: 1px dashed ${
  props => props.theme.global.colors.tableRowBorder
}!important;
    }

    th:last-child, td:last-child {
        padding-right: 0;
    }
`

const TableMarkerCell = styled(TableCell)<{ isActive: boolean }>`
    padding: 0 !important;
    ${props => props.isActive &&
  `
        border-left: 3px solid ${props.theme.global.colors.brandRho};
      `
}
`

export const LiquidityProvisions = (props: {
    market?: SelectMarket
    items: LiquidityProvisionSummary[]
}) => {
  const { items: liquidityProvisions } = props
  const navigate = useNavigate()

  const {  markets } = useProtocolData()
  const futures = markets.flatMap(market => market.futures)

  // const onOpenLiquidityClicked = (futureId: string) => {
  //   navigate(`/trade/liquidity/${id}/${futureId}`)
  // }

  return  <Box>
            <TableContainer>
                <TableHeader style={{ height: '35px' }}>
                    <TableRow>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>INSTRUMENT/RATE</Text>
                                <QuestionMark tooltipId={'lp_instrument'} tooltipText={tooltips.instrument} />
                            </Box>
                        </TableCell>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>TOTAL PROVISION</Text>
                                <QuestionMark tooltipId={'total_provision'} tooltipText={tooltips.totalProvision} />
                            </Box>
                        </TableCell>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>RECEIVER PROVISION</Text>
                                <QuestionMark tooltipId={'payerProvisionTooltip'} tooltipText={tooltips.receiverProvision} />
                            </Box>
                        </TableCell>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>PAYER PROVISION</Text>
                                <QuestionMark tooltipId={'payerProvisionTooltip'} tooltipText={tooltips.payerProvision} />
                            </Box>
                        </TableCell>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>RATE RANGE</Text>
                                <QuestionMark tooltipId={'lp_rate_range'} tooltipText={tooltips.rateRange} />
                            </Box>
                        </TableCell>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>LAST RATE</Text>
                                <QuestionMark tooltipId={'lp_last_rate'} tooltipText={'Last rate'} />
                            </Box>
                        </TableCell>
                        <TableCell scope="col">
                            <Box direction={'row'} align={'center'} gap={'2px'}>
                                <Text size={'12px'} color={'textSecondary'}>MR</Text>
                                <QuestionMark tooltipId={'lp_mr'} tooltipText={tooltips.marginRequired} />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                  {liquidityProvisions.map((item, index) => {
                    const { futureId, provisionInfo } = item
                    const future = futures.find((item) => item.id === futureId)
                    const market = markets.find(item => item.descriptor.id === future?.marketId)
                    const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
                    const underlyingName = market?.descriptor.underlyingName || ''

                    const { bounds, notional } = provisionInfo

                    let maturityTimestamp = 0
                    let lastRate = new bn(0)

                    if (future) {
                      const { termStart, termLength, vAMMParams } = future
                      maturityTimestamp = BigNumber.from(termStart)
                        .add(BigNumber.from(termLength))
                        .mul(1000).toNumber()

                      lastRate = new bn(vAMMParams.currentFutureRate.toString())
                        .div(Math.pow(10, 16))
                        .dp(2)
                    }

                    const futureRate = future ? bnToDecimal(future.vAMMParams.currentFutureRate, 16).toDecimalPlaces(2) : new Decimal(0).toDecimalPlaces(2)
                    const isMatured = maturityTimestamp < Date.now()

                    const instrumentInfo = props.market === 'all' && market && future
                        ? `${getFutureAlias(market, future)} (${futureRate}%)`
                        : `${getShortDate(maturityTimestamp)} (${futureRate}%)`

                    return <TableRow key={index} style={{ borderTop: '1px solid #383D57' }}>
                      <TableCell>
                        {instrumentInfo}
                      </TableCell>
                      <TableCell>
                        <Number
                          value={notional.total}
                          decimals={underlyingDecimals}
                          name={underlyingName} showName={true}
                        />
                      </TableCell>
                      <TableCell>
                        <Number
                          value={notional.receiver}
                          decimals={underlyingDecimals}
                          name={underlyingName} showName={true}
                        />
                      </TableCell>
                      <TableCell>
                        <Number
                          value={notional.payer}
                          decimals={underlyingDecimals}
                          name={underlyingName} showName={true}
                        />
                      </TableCell>
                      <TableCell>
                        <Box direction={'row'} gap={'4px'}>
                          <Box>
                            {bn(fromBn(bounds.lower, 16)).dp(2).toString()}%
                          </Box>
                          <Box>
                            <Text color={'arrow'} size={'20px'}>→</Text>
                          </Box>
                          <Box>
                            {bn(fromBn(bounds.upper, 16)).dp(2).toString()}%
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {lastRate.toString()}%
                      </TableCell>
                      <TableCell>
                        <Number
                          value={provisionInfo.requiredMargin}
                          decimals={underlyingDecimals}
                          name={underlyingName}
                        />
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>
            </TableContainer>
        </Box>
}
