import React from 'react'
import {Anchor, Box, Text} from "grommet";
import {NotificationBadge} from "../../../components";
import {ReactComponent as Link2Img} from '../../../assets/images/link2.svg';

export const RestrictedAccess = () => {
  return <NotificationBadge
    type={'warning'}
    title={'Your address should be whitelisted to access our Private Beta'}
    description={<Box>
      <Anchor href={'https://ttmfil8w9mg.typeform.com/to/TReoDDdq?utm_source=app&utm_medium=mainnet&utm_campaign=beta_access'} target={'_blank'} >
          <Box direction={'row'} align={'center'} gap={'4px'}>
              <Text color={'textWarning'} weight={400} style={{ textDecoration: 'underline' }}>Request access here</Text>
              <Link2Img style={{ color: '#D6B65C', width: '14px', height: '14px' }} />
          </Box>
      </Anchor>

    </Box>}
  />
}
