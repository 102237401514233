import React from 'react'
import {Box} from "grommet";
import {Deposited} from "./Deposited";
import {Rewards} from "./Rewards";
import {VaultInfoPage} from "./VaultInfo";
import {EarnHeader} from "./Header";
import {VaultProps} from "./common";
import {useVault} from "../../providers/VaultDataProvider";
import {TransactionsHistory} from "./TransactionsHistory";

export const EarnPage = () => {
  const {
    vaults,
    vault,
    vaultPortfolio,
    isInitialLoading: isVaultLoading,
    refetch: refetchVaultBalance,
  } = useVault()

  const vaultProps: VaultProps = {
    vaults,
    vault,
    vaultPortfolio,
    isVaultLoading,
    refetchVaultBalance
  }

  return <Box margin={{ bottom: '42px' }} style={{ minWidth: '700px' }}>
    <Box margin={{ top: '42px' }}>
      <EarnHeader />
    </Box>
    <Box margin={{ top: '32px' }} direction={'row'} gap={'32px'}>
      <Deposited {...vaultProps} />
      {/*<Rewards />*/}
    </Box>
    <Box margin={{ top: '16px' }}>
      <VaultInfoPage {...vaultProps} />
    </Box>
    <Box margin={{ top: '16px' }}>
      <TransactionsHistory {...vaultProps} />
    </Box>
  </Box>
}
