import React from 'react'
import {TradeProps} from "../../common";
import {Box} from "grommet";
import {Button} from "antd";
import { ReactComponent as DepositImg } from '../../../../assets/images/deposit.svg'
import { ReactComponent as WithdrawImg } from '../../../../assets/images/withdraw.svg'
import {PageModal} from "../../../../constants";
import {useNavigate} from "react-router-dom";
import {MarketMargin, MarketLeverage} from "../../../../components";

export const MarginManagement = (props: TradeProps) => {
  const { marketId } = props
  const navigate = useNavigate()

  const onOpenManageMargin = (type: 'deposit' | 'withdraw') => {
    navigate(`?modal=${PageModal.margin}&type=${type}&marketId=${marketId}`)
  }

  return <Box
    direction={'row'}
    margin={{ top: '24px', left: '16px' }}
    gap={'48px'}
    align={'center'}
  >
    <MarketMargin marketId={props.marketId} showLMR={true} />
    <MarketLeverage marketId={props.marketId} />
    <Box pad={'16px'} gap={'16px'} border={{ size: '1px' }} round={'6px'}>
      <Button
        icon={<Box><DepositImg /></Box>}
        style={{ background: '#2E2E38', border: 'none', textAlign: 'left' }}
        onClick={() => onOpenManageMargin('deposit')}
      >
        Deposit
      </Button>
      <Button
        icon={<Box><WithdrawImg /></Box>}
        style={{ background: '#2E2E38', border: 'none', textAlign: 'left' }}
        onClick={() => onOpenManageMargin('withdraw')}
      >
        Withdraw
      </Button>
    </Box>
  </Box>
}
