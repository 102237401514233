import {getSquidStatus, SquidRouterQuote, SquidStatus, SquidStatusType, SquidStatusTypeAlias} from "../api/squid";
import {useContractWrite, useNetwork, useSendTransaction} from "wagmi";
import {useState} from "react";
import {switchNetwork, waitForTransaction, writeContract} from "@wagmi/core";
import erc20MockABI from "../abi/erc20MockABI.json";

export interface SquidTransactionRequest {
  transactionHash: string
  quote: SquidRouterQuote
  status?: SquidStatus
  timestamp: number
}

export const useSquidRouteExecute = () => {
  const { chain } = useNetwork()
  const { data: hash, sendTransactionAsync } = useSendTransaction()

  const [requests, setRequests] = useState<SquidTransactionRequest[]>([])

  const execute = async (quote: SquidRouterQuote) => {
    try {
      if(+quote.route.params.fromChain !== chain?.id) {
        await switchNetwork({
          chainId: +quote.route.params.fromChain
        })
      }

      // Catch error for native tokens (ETH, MATIC, ...)
      try {
        const approveTx = await writeContract({
          address: quote.route.params.fromToken as `0x${string}`,
          abi: erc20MockABI,
          functionName: 'approve',
          args: [quote.route.transactionRequest.target, quote.route.params.fromAmount]
        })
        console.log('approveTx: ', approveTx.hash)
        await waitForTransaction({ hash: approveTx.hash, chainId: quote.route.params.fromChain })
      } catch (e) {}

      const transactionRequest = quote.route.transactionRequest;

      const result = await sendTransactionAsync({
        data: transactionRequest.data as `0x${string}`,
        to: transactionRequest.target,
        value: transactionRequest.value as any,
        gas: transactionRequest.gasLimit as any,
        gasPrice: transactionRequest.gasPrice as any,
      })

      console.log('useSquidRouteExecute: transaction hash', result.hash)

      const txRequest: SquidTransactionRequest = {
        transactionHash: result.hash,
        quote: {...quote},
        timestamp: Date.now(),
      }

      setRequests((currentValues) => [txRequest, ...currentValues])
      pollStatus(txRequest)

      return txRequest
    } catch(e) {
      console.error('Failed to execute squid route', e)
    }
  }

  const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

  const pollStatus = async (request: SquidTransactionRequest) => {
    const { transactionHash, quote } = request

    for(let i = 0; i < 100; i++) {
      try {
        const status = await getSquidStatus({
          requestId: quote.requestId,
          transactionId: transactionHash,
          fromChainId: quote.route.params.fromChain,
          toChainId: quote.route.params.toChain
        })

        setRequests((currentRequests) => currentRequests.map(item => {
          if(item.transactionHash === transactionHash) {
            return {
              ...item,
              status
            }
          }
          return item
        }))

        if([
          SquidStatusType.EXPRESS_EXECUTED,
          SquidStatusType.DEST_ERROR,
          SquidStatusType.DEST_ERROR,
          SquidStatusType.SUCCESS
        ].includes(status.status)) {
          console.log(`useSquidRouteExecute: transaction ${transactionHash} success! Squid status: ${status}.`)
          break
        }
      } catch (e) {
        console.error(`Failed to get squid status ${transactionHash}:`, e)
      } finally {
        await sleep(5000)
      }
    }
  }

  return {
    execute,
    requests
  }
}
