import React from 'react'
import {Box, Text} from "grommet";
import {ReactComponent as RhoLogo} from '../../assets/images/rho_logo.svg';

export const MobileNotSupported = () => {
  return <Box
    height={'100%'}
    justify={'center'}
    align={'center'}
    gap={'32px'}
  >
    <Box width={'80%'} gap={'16px'} justify={'center'} align={'center'}>
      <Text size={'16px'} weight={500}>Mobile devices are not supported yet.</Text>
      <Text size={'16px'} textAlign={'center'}>Please switch to your desktop or iPad to use Rho Protocol.</Text>
    </Box>
    <Box>
      <RhoLogo width={'28px'} height={'34px'} />
    </Box>
  </Box>
}
