import React, {useEffect, useMemo, useState} from 'react'
import {Box, Button, Spinner, Text} from "grommet";
import {useSearchParams} from "react-router-dom";
import {
  useAccount,
  useContractWrite,
  useBalance,
  useNetwork,
  useContractRead,
  erc20ABI, usePublicClient
} from "wagmi";
import {FormError, MarginState, MarketInfo, MarketPortfolio} from "../../types";
import config from "../../config";
import RouterABI from '../../abi/RouterABI.json'
import {BigNumber} from "ethers";
import {MarginTransferRequest} from "./Request";
import {getOraclePackage, prepareFormNumber} from "../../utils";
import erc20MockABI from "../../abi/erc20MockABI.json";
import {getFormError} from "./validator";
import {
  RouterErrorMessage,
  WidgetContainer,
  UnsupportedNetwork, PrimaryButton,
} from "../../components";
import { ReactComponent as CrossImg } from "../../assets/images/cross.svg";
import { Button as AntdButton } from 'antd'
import {marginSuccessNotification} from "../../components/notifications";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {marginTotal} from "../../utils/mappers";
import {useWithdrawableMargin} from "../../hooks/useWithdrawableMargin";
import {toast} from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import {switchNetwork, waitForTransaction} from "@wagmi/core";
import {getAllowance} from "../../api/erc20Contract";

export interface MarginTransferParams {
  markets: MarketInfo[]
  marketId: string
  market: MarketInfo
  destinationMarketId: string,
  destinationMarket?: MarketInfo
  marginDetails?: MarginState
  formValues: FormValuesState
  nativeTokenBalance: BigNumber
  underlyingAddress: string,
  underlyingName: string,
  underlyingBalance: BigNumber
  withdrawableMargin: BigNumber
  allowance: BigNumber
  marketPortfolio?: MarketPortfolio
  fromTokenBalance: BigNumber
  fromTokenDecimals: number
  isTransferInProgress: boolean
  setMarketId: (id: string) => void
  setDestinationMarketId: (id: string) => void
}

interface FormValuesState {
  amount: string,
  wrapNativeToken: boolean,
  sourceChainId: number
  tokenSymbol: string
  currentStep: 'approve' | 'withdraw' | 'deposit' | 'done'
}

type FormValueProperty = {
  [K in keyof FormValuesState]?: FormValuesState[K];
};

export interface ManageMarginProps extends MarginTransferParams {
  formError: FormError | null
  onChangeFormValue: (newState: FormValueProperty) => void
}

const defaultValuesState: FormValuesState = {
  amount: '0',
  wrapNativeToken: false,
  sourceChainId: config.chainId,
  tokenSymbol: 'ETH',
  currentStep: 'approve'
}

export interface ManageMarginContainerProps {
  marketId?: string
  onClose: () => void
}

export const MarginTransfer = (props: ManageMarginContainerProps) => {
  const { chain } = useNetwork()
  const { isConnected, address: userAddress } = useAccount()
  const client = usePublicClient()

  const {
    markets,
    portfolio,
    refetchPortfolio,
    portfolioInitialFetching: isLoading,
    history,
  } = useProtocolData()

  const {
    amount: withdrawableMargin,
    refetch: refetchWithdrawableMargin
  } = useWithdrawableMargin(props.marketId)

  const [searchParams, setSearchParams] = useSearchParams()
  const marketIdFromQuery = searchParams.get('marketId')
  const initialMarketId = marketIdFromQuery || props.marketId || ''
  const initialDestinationMarket = markets.find(market => market.descriptor.id !== initialMarketId)

  const [marketId, setMarketId] = useState(initialMarketId)
  const [destinationMarketId, setDestinationMarketId] = useState('')
  const [formValues, setValues] = useState<FormValuesState>(defaultValuesState)
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))
  const debouncedAmount = useDebounce(formValues.amount, 350)
  const destinationMarket = markets.find(item => item.descriptor.id === destinationMarketId)

  const [isTransferInProgress, setTransferInProgress] = useState<boolean>(false)
  const [txError, setTxError] = useState<Error | null>(null)
  const [txTimestamp, setTxTimestamp] = useState(0)

  const [marginDetails, setMarginState] = useState<MarginState>()

  const market = markets.find((market) => market.descriptor.id === marketId)
  const underlyingAddress = market ? market.descriptor.underlying : ''
  const underlyingDecimals = market ? market.descriptor.underlyingDecimals : 18
  const underlyingName = market ? market.descriptor.underlyingName : ''
  const amount = useMemo(() => {
    return prepareFormNumber(formValues.amount, underlyingDecimals)
  }, [formValues.amount, underlyingDecimals])

  const { data: underlyingBalanceData } = useContractRead({
    address: underlyingAddress as `0x${string}`,
    functionName: 'balanceOf',
    abi: erc20ABI,
    args: [userAddress as `0x${string}`],
    enabled: !!userAddress,
    chainId: formValues.sourceChainId
  })
  const underlyingBalance = BigNumber.from(underlyingBalanceData || '0')

  const { data: nativeTokenBalanceData } = useBalance({
    address: userAddress,
    watch: true,
    chainId: formValues.sourceChainId
  })
  const nativeTokenBalance = nativeTokenBalanceData ? BigNumber.from(nativeTokenBalanceData?.value.toString()) : BigNumber.from(0)

  const marketPortfolio = portfolio.find(item => item.descriptor.id === marketId)
  const fromTokenBalance = marketPortfolio ? marginTotal(marketPortfolio.marginState.margin) : BigNumber.from(0)
  const fromTokenDecimals = underlyingDecimals

  const { writeAsync: callApprove } = useContractWrite({
    address: (market ? market.descriptor.underlying : '0x') as `0x${string}`,
    abi: erc20MockABI as any[],
    functionName: 'approve',
    args: [config.routerContractAddress, amount.toString()],
    // gas: BigInt(10000000)
  })

  const onChangeFormValue = (newState: FormValueProperty) => {
    setValues((currentState) => {
      return {
        ...currentState,
        ...newState
      }
    })
  }

  const {
    writeAsync: depositRequest,
    isLoading: isDepositInitiated
  } = useContractWrite({
    address: config.routerContractAddress,
    abi: RouterABI,
    functionName: 'deposit',
    // gas: BigInt(10000000)
  })

  const {
    writeAsync: withdrawRequest,
    isLoading: isWithdrawInitiated
  } = useContractWrite({
    address: config.routerContractAddress,
    abi: RouterABI,
    functionName: 'withdraw',
    args: [
      marketId,
      amount,
    ],
    // gas: BigInt(10000000)
  })

  useEffect(() => {
    if(!destinationMarketId && initialDestinationMarket) {
      setDestinationMarketId(initialDestinationMarket.descriptor.id)
    }
  }, [initialDestinationMarket, destinationMarketId]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if(underlyingAddress && userAddress) {
          const allowanceData = await getAllowance(underlyingAddress, userAddress)
          setAllowance(allowanceData)
          console.log('Updated allowance:', allowanceData.toString())
        }
      } catch (e) {
        console.error(`Cannot load ERC20 data:`, e)
      }
    }
    loadData()
  }, [underlyingAddress, userAddress]);

  useEffect(() => {
    if(marketIdFromQuery) {
      setMarketId(marketIdFromQuery)
      onChangeFormValue({ 'amount': '0' })
    }
  }, [marketIdFromQuery]);

  useEffect(() => {
    if(Date.now() - txTimestamp > 1000) {
      setTxError(null)
    }
  }, [formValues.amount])

  if(!market) {
    return <Box width={'100%'} justify={'center'} align={'center'} pad={{ top: '32px', bottom: '32px' }}>
      {isLoading && <Spinner color={'spinner'} size={'small'} />}
    </Box>
  }

  const onRequestClicked = async () => {
    try {
      setTransferInProgress(true)
      setValues((currentState) => ({...currentState, currentStep: 'approve'}))

      console.log(`Start margin transfer, amount: ${amount.toString()}, allowance: ${allowance.toString()}`)

      // 1/3: Set allowance
      if(amount.gt(allowance)) {
        const approveResult = await callApprove()
        await waitForTransaction({ hash: approveResult.hash, confirmations: config.txConfirmations }).catch(e => {
          console.error(`Failed on wait for approval transaction ${approveResult.hash}:`, e)
        })
      }

      setValues((currentState) => ({...currentState, currentStep: 'withdraw'}))
      // 2/3: WITHDRAW
      const oraclePackage = await getOraclePackage(marketId)
      const withdrawArgs = [
        marketId,
        formValues.wrapNativeToken,
        amount,
        true,
        [oraclePackage]
      ]
      let gas = undefined
      if(config.gasEstimateMultiplier) {
        try {
          gas = await client.estimateContractGas({
            address: config.routerContractAddress,
            abi: RouterABI,
            functionName: 'withdraw',
            args: withdrawArgs,
            account: userAddress as `0x${string}`
          })
          gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier  ))
          console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`)
        } catch (e) {
          console.error(`Failed to estimate gas:`, e)
        }
      }
      console.log('Withdraw arguments: ', withdrawArgs)
      const withdrawResult = await withdrawRequest({ args: withdrawArgs, gas })
      await waitForTransaction({ hash: withdrawResult.hash, confirmations: config.txConfirmations }).catch(e => {
        console.error(`Failed on wait for withdraw transaction ${withdrawResult.hash}:`, e)
      })

      setValues((currentState) => ({...currentState, currentStep: 'deposit'}))

      // 3/3: DEPOSIT
      const depositArgs = [
        destinationMarketId,
        userAddress,
        formValues.wrapNativeToken ? '0' : amount,
        true,
        [],
      ]

      if(config.gasEstimateMultiplier) {
        try {
          gas = await client.estimateContractGas({
            address: config.routerContractAddress,
            abi: RouterABI,
            functionName: 'deposit',
            args: depositArgs,
            account: userAddress as `0x${string}`
          })
          gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier))
          console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`)
        } catch (e) {
          console.error('Failed to estimate gas:', e)
        }
      }
      console.log('Deposit arguments:', depositArgs)
      const depositResult = await depositRequest({
        args: depositArgs,
        value: formValues.wrapNativeToken ? BigInt(amount.toString()) : undefined,
        gas
      })
      await waitForTransaction({ hash: depositResult.hash, confirmations: config.txConfirmations }).catch(e => {
        console.error(`Failed on wait for deposit transaction ${depositResult.hash}:`, e)
      })
      setValues((currentState) => ({...currentState, currentStep: 'done', amount: '0'}))
      refetchWithdrawableMargin()
      refetchPortfolio()

      if(depositResult) {
        const text = <Box>
          <Text>Successfully transferred {formValues.amount} {underlyingName} </Text>
          <Text>from {market.descriptor.sourceName} {market.descriptor.instrumentName}</Text>
          <Text>to {destinationMarket?.descriptor.sourceName} {destinationMarket?.descriptor.instrumentName}</Text>
        </Box>
        marginSuccessNotification({
          type: 'deposit',
          text,
          transactionHash: depositResult.hash,
        })
      }
    } catch (e) {
      console.error(`Error on transfer:`, e)
      toast.error(`Transfer failed. Try again later.`)
    } finally {
      setTransferInProgress(false)
    }
  }

  const formParams: MarginTransferParams = {
    markets,
    marketId,
    market,
    destinationMarketId,
    destinationMarket,
    marginDetails,
    formValues,
    nativeTokenBalance,
    underlyingAddress,
    underlyingName,
    underlyingBalance,
    withdrawableMargin,
    allowance,
    marketPortfolio,
    fromTokenBalance,
    fromTokenDecimals,
    isTransferInProgress,
    setMarketId: (marketId: string) => {
      const newSearch = new URLSearchParams(searchParams);
      newSearch.set('marketId', marketId)
      setSearchParams(newSearch)
    },
    setDestinationMarketId
  }
  const formError = getFormError(formParams)
  const commonProps: ManageMarginProps = {
    ...formParams,
    formError,
    onChangeFormValue
  }


  let actionButtons = null
  if(!isConnected) { //  isConnected && chain && (chain.unsupported || chain.id !== config.chainId)
    actionButtons = <Box margin={{ top: '24px' }}>
      <UnsupportedNetwork />
    </Box>
  } else {
    actionButtons = <Box direction={'row'} justify={'end'} gap={'16px'} margin={{ top: '30px' }}>
      {txError &&
          <RouterErrorMessage
              prefix={'Request error'}
              error={txError}
          />
      }
      {(chain?.id !== config.chainId) &&
          <Button
              style={{ padding: '10px 16px', background: '#444CE4', minWidth: '162px' }}
              onClick={() => {
                switchNetwork({ chainId: config.chainId }).catch(e => {})
              }}
          >
              <Box pad={'2px 0'}>
                  Switch network
              </Box>
          </Button>
      }
      {(chain?.id === config.chainId) &&
        <PrimaryButton
            disabled={isTransferInProgress || amount.eq(0) || !!formError}
            text={isTransferInProgress ? 'Processing transfer...' : 'Transfer'}
            onClick={onRequestClicked}
        />
      }
      {!isConnected &&
          <Button
              style={{ padding: '12px 32px' }}
              disabled={true}
          >
              <Text>Connect wallet</Text>
          </Button>
      }
    </Box>
  }

  return <Box align={'center'}>
    <WidgetContainer style={{ position: 'relative', padding: '24px' }}>
      <Box direction={'row'} justify={'between'} align={'center'}>
        <Text color={'textHeader'} size={'20px'} weight={500}>Margin transfer</Text>
        <AntdButton type={'text'} onClick={() => {
          props.onClose()
        }}>
          <Box justify={'center'} align={'center'}>
            <CrossImg />
          </Box>
        </AntdButton>
      </Box>
      <Box margin={{ top: '40px' }}>
        <MarginTransferRequest {...commonProps} />
      </Box>
      {actionButtons}
    </WidgetContainer>
  </Box>
}
