import {useMemo} from "react";
import {prepareFormNumber} from "../utils";
import {marginTotal} from "../utils/mappers";
import {FutureInfo, MarketInfo, MarketPortfolio, RiskDirection, TradeQuote} from "../types";
import {useMaxLeverage} from "./useMaxLeverage";
import {BigNumber} from "ethers";
import {FormValuesState} from "../pages/trade/common";

const zero = BigNumber.from(0)

export const useInsufficientMargin = (params: {
  tradeQuote: TradeQuote | null
  market?: MarketInfo
  future?: FutureInfo
  portfolio?: MarketPortfolio
  formValues: FormValuesState
  leverage: number
  leverageRange: { min: number, max: number }
}) => {
  const {
    market,
    future,
    portfolio,
    formValues,
    tradeQuote,
    leverage,
    leverageRange
  } = params
  const { maxShortLeverage, maxLongLeverage } = useMaxLeverage({ market, future, portfolio, leverageRange })

  return useMemo(() => {
    const selectedQuote = formValues.riskDirection === RiskDirection.payer
      ? tradeQuote?.payerQuote
      : tradeQuote?.receiverQuote
    const underlyingDecimals = market ? market.descriptor.underlyingDecimals : 6
    const currentMargin = portfolio ? marginTotal(portfolio.marginState.margin) : zero
    const isInsufficientMargin = currentMargin.eq(0)
      || (prepareFormNumber(formValues.notional, underlyingDecimals).gt(0) &&
        (+leverage.toFixed(2) > +leverageRange.max.toFixed(2)
          || +leverage.toFixed(2) < +leverageRange.min.toFixed(2))
      ) || leverage < maxShortLeverage || leverage > maxLongLeverage
      || (selectedQuote ? marginTotal(selectedQuote.newMargin).lt(selectedQuote.newMarginThreshold) : false)
    return isInsufficientMargin
  }, [
    market,
    portfolio,
    future,
    formValues.notional,
    leverage,
    leverageRange,
    tradeQuote
  ])
}
