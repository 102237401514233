import React, {useEffect, useRef, useState} from 'react'
import {Box, Text} from "grommet";
import {
  Arrow,
  MarketIcon,
  OpTypeBadge, WidgetContainer,
} from "../";
import {FutureInfo, MarketInfo, OpType} from "../../types";
import moment from "moment";
import {MarketsSelect} from "./MarketsSelect";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {ScrollableContainer} from "../scroll";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {Skeleton} from "antd";

export type SelectMarket = MarketInfo | 'all'

export interface MarketSelectProps {
  viewType: string
  width?: string
  searchValue?: string
  mode?: 'default' | 'markets'
  market?: SelectMarket
  future?: FutureInfo
  onDropdownChange?: (isDropdownVisible: boolean) => void
  onSelect: (market: SelectMarket, future?: FutureInfo) => void
}

export const MarketSelect = (props: MarketSelectProps) => {
  const { viewType = 'default', width, mode = 'default', market, future } = props

  const ref = useRef<any>();
  const { markets } = useProtocolData()

  const [isModalOpen, setIsModalOpen] = useState(false);

  useOnClickOutside(ref, () => toggleModal(false));

  const toggleModal = (isOpened: boolean) => {
    setIsModalOpen(isOpened)
    if(props.onDropdownChange) {
      props.onDropdownChange(isOpened)
    }
  }

  const isMarketSelected = market && market !== 'all'
  const sourceName = isMarketSelected ? market.descriptor.sourceName: ''
  const marketName = isMarketSelected ? `${market.descriptor.sourceName} ${market.descriptor.instrumentName}` : 'All markets'
  const underlyingName = isMarketSelected ? market.descriptor.underlyingName : ''
  const tag = isMarketSelected ? market.descriptor.tag : ''

  const onMarketSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectElements = document.querySelectorAll('.rho_compact_select')
    // @ts-ignore
    if(selectElements.length > 0 && selectElements[0].contains(e.target)){
    } else {
      toggleModal(!isModalOpen)
    }
  }

  return <WidgetContainer
    ref={ref}
    style={{
      padding: 0,
      borderBottomLeftRadius: isModalOpen ? '0px' : '12px',
      borderBottomRightRadius: isModalOpen ? '0px' : '12px',
    }}
  >
    <Box
      direction={'row'}
      align={'center'}
      justify={'between'}
      onClick={onMarketSelectClick}
      style={{
        padding: '16px'
      }}
    >
      <Box direction={'row'} align={'center'}>
        {market
          ? <MarketIcon
            name={market === 'all' ? 'layer' : sourceName}
            tokenName={market === 'all' ? '' : underlyingName}
            size={'45px'}
            iconSize={'28px'}
          />
          : <Skeleton.Avatar active size={45} />
        }
        <Box margin={{ left: '16px' }}>
          <Box direction={'row'} align={'center'}>
            {marketName
              ? <Text size={'18px'} weight={500} color={'accentWhite'}>
                  {marketName}
                </Text>
              : <Skeleton.Input active size={'small'} style={{ width: '240px' }} />
            }
            {mode === 'default' &&
                <Box margin={{ left: '10px' }}>
                    <OpTypeBadge opType={tag as OpType} />
                </Box>
            }
          </Box>
          {(mode === 'default' && future) &&
              <Box>
                  <Text color={'textSecondary'}>
                      Maturity: {moment((future.termStart.add(future.termLength).toNumber() * 1000)).format('DD-MM-YYYY')}
                  </Text>
              </Box>
          }
        </Box>
      </Box>
      <Box justify={'center'} margin={{ left: '20px', right: '8px' }}>
        <Arrow direction={isModalOpen ? 'up' : 'down'} />
      </Box>
    </Box>
    <Box
      style={{ position: 'relative' }}
    >
      <ScrollableContainer
        style={{
          background: '#1E1E20',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          position: 'absolute',
          width: viewType === 'small' ? '100%' : '520px',
          minWidth: width ? `max(520px, ${width})` : '520px',
          maxHeight: viewType === 'small' ? '400px' : 'calc(100vh - 164px)',
          minHeight: '100px',
          left: '0px',
          top: '0px',
          zIndex: 5,
          display: isModalOpen ? 'block' : 'none'
        }}
      >
        <MarketsSelect
          {...props}
          markets={markets}
          onSelect={(market, future) => {
            toggleModal(false)
            props.onSelect(market, future)
          }}
        />
      </ScrollableContainer>
    </Box>
  </WidgetContainer>
}
