import React from 'react'
import {Box, Text} from "grommet";
import {GradientButton, Number, PrimaryButton, QuestionMark} from "../../components";
import tooltips from "../../utils/tooltips";
import {EarnWidget, VaultProps} from "./common";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "antd";
import {BigNumber} from "ethers";
import styled, {keyframes} from "styled-components";
import {useVault} from "../../providers/VaultDataProvider";
import {useAccount} from "wagmi";
import moment from "moment";
import {VaultInfo} from "../../types";
import config from "../../config";
import Decimal from 'decimal.js'

const zero = BigNumber.from(0)

const breatheAnimation = keyframes`
  0% { height: 100%; width: 100%; opacity: 1; }
  50% { height: 80%; width: 80%; opacity: 0.8; }
  100% { height: 100%; width: 100%; opacity: 1; }
`

const UnderlyingContainer = styled(Box)`
    position: absolute;
    width: 90%;
    height: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: #9470DB60 0 10px 350px 10px;
    z-index: 0;
    border-radius: 12px;
    animation-name: ${breatheAnimation};
    animation-duration: 6s;
    animation-iteration-count: infinite;
`

const calculateCurrentAPY = (vault?: VaultInfo) => {
  if(vault && config.vaultLaunchTimestamp) {
    const daysSinceLaunch = moment().diff(moment(config.vaultLaunchTimestamp * 1000), 'days')
    if(daysSinceLaunch > 0) {
      // const growthFactor = (new Decimal(vault.totalAssets.toString())
      //   .div(new Decimal(vault.totalSupply.toString())))
      // const dailyReturn = growthFactor.pow((new Decimal(1).div(daysSinceLaunch)))
      // const apy = (dailyReturn.pow(new Decimal(365))).sub(new Decimal(1))
      // return apy.mul(100).toFixed(0)

      const vaultCurrentIndex = (new Decimal(vault.totalAssets.toString())
        .div(new Decimal(vault.totalSupply.toString())))
      const { vaultIndexAtLaunch } = config

      // apy = (index period_end / index period_start )ˆ(365/period_days) -1

      const apy = (
        (vaultCurrentIndex.div(new Decimal(vaultIndexAtLaunch)))
          .pow(new Decimal(365).div(daysSinceLaunch))
        ).sub(1)
      return apy.mul(100).toFixed(0)
    }
  }
  return '0'
}

export const Deposited = (props: VaultProps) => {
  const navigate = useNavigate()

  const { address: userAddress } = useAccount()

  const {
    vault,
    vaultPortfolio,
    totalEarned,
    isInitialLoading,
    isVaultUser,
  } = useVault()

  return <Box
    style={{ position: "relative" }}
    width={'100%'}
    // width={'calc(100% - 220px - 32px)'}
  >
    <UnderlyingContainer />
    <EarnWidget
      background={'widgetBg'}
      justify={'between'}
    >
      <Box direction={'row'} justify={'between'}>
        <Box gap={'16px'}>
          <Box>
            <Box direction={'row'} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>In vault</Text>
            </Box>
            {!userAddress
              ? <Number
                value={zero}
                decimals={6}
                fontSize={'28px'}
                fontColor={'textHeader'}
                name={'USDT'}
                showName={true}
              />
              : !vault
              ? <Skeleton.Input active size={'small'} style={{ width: '140px', height: '35px' }} />
              : <Number
                  value={vault.maxWithdraw}
                  decimals={vault.underlyingDecimals}
                  fontSize={'28px'}
                  fontColor={'textHeader'}
                  name={vault.underlyingName}
                  showName={true}
                />
            }
          </Box>
          <Box>
            <Box direction={'row'} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>You have earned</Text>
            </Box>
            {!userAddress
              ? <Number
                  value={zero}
                  decimals={6}
                  fontSize={'28px'}
                  fontColor={'textHeader'}
                  name={'USDT'}
                  showName={true}
                />
              : (!vault || !vaultPortfolio)
              ? <Skeleton.Input active size={'small'} style={{ width: '140px', height: '33px' }} />
              : <Number
                  value={totalEarned}
                  decimals={vault.underlyingDecimals}
                  fontSize={'28px'}
                  fontColor={'textHeader'}
                  name={vault.underlyingName}
                  showName={true}
                />
            }
          </Box>
          {(!vault || vault.userPendingWithdrawalsAmount.eq(0))
            ? null
            : <Box>
                <Box direction={'row'} align={'center'} gap={'4px'}>
                  <Text color={'textSecondary'}>Pending withdrawals</Text>
                </Box>
                <Number
                  value={vault.userPendingWithdrawalsAmount}
                  decimals={vault.underlyingDecimals}
                  fontSize={'16px'}
                  fontColor={'textHeader'}
                  name={vault.underlyingName}
                  showName={true}
                />
            </Box>
          }
          {/*<Box>*/}
          {/*  <Box direction={'row'} align={'center'} gap={'4px'}>*/}
          {/*    <Text color={'textSecondary'}>Your income</Text>*/}
          {/*  </Box>*/}
          {/*  {isVaultLoading*/}
          {/*    ? <Skeleton.Input active size={'small'} style={{ width: '240px', height: '33px' }} />*/}
          {/*    : <Box direction={'row'} align={'baseline'} gap={'6px'}>*/}
          {/*      <Text size={'28px'} color={'textSecondary'}>{formatNumber(tokensEarned.toString())} {vault?.underlyingName}</Text>*/}
          {/*      {tokensEarned.gt(0) &&*/}
          {/*          <Text size={'14px'} color={'positiveValue'}>(+0.8%)</Text>*/}
          {/*      }*/}
          {/*    </Box>*/}
          {/*  }*/}
          {/*</Box>*/}
          {/*<Box>*/}
          {/*  <Box direction={'row'} align={'center'} gap={'4px'}>*/}
          {/*    <Text color={'textSecondary'}>Total</Text>*/}
          {/*  </Box>*/}
          {/*  {isVaultLoading*/}
          {/*    ? <Skeleton.Input active size={'small'} style={{ width: '240px', height: '26px' }} />*/}
          {/*    : <Text size={'22px'} color={'textHeader'}>*/}
          {/*      {formatNumber(deposited.toString())} {vault?.underlyingName}*/}
          {/*    </Text>*/}
          {/*  }*/}
          {/*</Box>*/}
        </Box>
        <Box align={'end'}>
          <Box direction={'row'} align={'center'} gap={'4px'}>
            <Text color={'textSecondary'}>APY</Text>
            <QuestionMark tooltipId={'apy'} tooltipText={tooltips.apy} />
          </Box>
          {vault
            ? <Text size={'28px'} color={'accentWhite'}>{calculateCurrentAPY(vault)}%</Text>
            : <Skeleton.Input active size={'small'} style={{ width: '140px', height: '35px' }} />
          }
        </Box>
      </Box>
      <Box margin={{ top: '24px' }} direction={'row'} gap={'16px'}>
          {isVaultUser || (!isVaultUser && isInitialLoading) ?
            <Box width={'220px'}>
              <GradientButton
                text={'Invest'}
                disabled={isInitialLoading}
                size={'18px'}
                style={{ height: '100%', minHeight: '100%', minWidth: '100%' }}
                onClick={() => {
                  navigate('?modal=invest')
                }}
              />
            </Box>
            : <Box width={'240px'}>
                <PrimaryButton
                text={'User not whitelisted'}
                disabled={true}
                size={'18px'}
                style={{ height: '100%', minHeight: '100%', minWidth: '100%' }}
                onClick={() => {
                  navigate('?modal=invest')
                }}
              />
            </Box>
          }
        <Box>
          <PrimaryButton
            viewType={'secondary'}
            text={'Redeem'}
            disabled={!vault || vault.maxWithdraw.sub(vault.userPendingWithdrawalsAmount).lte(0)}
            size={'14px'}
            style={{ height: '100%', minHeight: '100%', minWidth: '100%' }}
            onClick={() => {
              navigate('?modal=redeem')
            }}
          />
        </Box>
      </Box>
    </EarnWidget>
  </Box>
}
