const tooltips = {
  riskDirection: `Defines your position as either a 'Payer of Fixed Rates' or a 'Receiver of Fixed Rates'. As a 'Payer', you expect interest rates to rise, effectively benefiting from paying a fixed rate while receiving a higher variable rate. As a 'Receiver', you expect rates to fall, hence benefiting from receiving a fixed rate and paying a lower variable rate.`,
  accruedNotional: `The principal amount, starting value of your position, adjusted to include any compounded interest over time. In markets with linear rates, like funding rates, this value remains constant. But for liquid staking tokens like stETH, interest compounds daily, causing the notional to increase.`,
  dv01: `A measure of sensitivity, DV01 indicates the change in your position's value for each 1 basis point in interest rates (1bp = 0.01%).`,
  currentMargin: `Reflects the existing collateral balance, factoring in any margin adjustments, P&L, and trading fees. It serves as a buffer against potential trading losses.`,
  marginRequired: `The minimum collateral to open or add to positions. If margins drop below this level, both trading and withdrawals are suspended until margins are restored. Lower margins increase liquidation risk.`,
  notional: `The principal amount used as the reference value for calculating interests on rate futures contracts.`,
  deposit: `Funds added as collateral to meet margin requirements. Increasing the deposit reduces leverage, lowering liquidation risk.`,
  minMaxRateLimit: `Sets the acceptable deviation from the current market rate during trade execution, considering slippage risks. Defined in percentage points, it establishes the minimum rate for receivers and the maximum for payers.`,
  expectedRate: `The projected rate for executing the trade, as determined by the protocol considering the trade size and current vAMM liquidity. This estimate does not account for potential slippage risks (differences between expected and actual execution prices).`,
  tradingFees: `Trading charges based on the trade size and its associated price impact. Mainly used to reward liquidity providers in the vAMM.`,
  totalMarginRequired: `The minimum amount of collateral needed for both trading and providing liquidity in the vAMM. If your margin goes below the set limit, you can't add more liquidity or withdraw collateral until it's restored. However, you can continue trading if your margin is still above the Initial Margin Requirement (IMR).`,
  dueToOpenPosition: `Represents the initial margin requirement for existing trading positions.`,
  dueToLiquidityProvision: `Margin set aside to support the notional value of liquidity provided, ensuring adequate collateral for trades that occur within this liquidity range.`,
  rateRange: `Specifies the rate interval where you commit liquidity, defined in notional terms. When futures are traded above the current market rate, you provide liquidity for those paying fixed rates, implying you will receive rates upon trade execution. Conversely, if the market moves downwards and trades below the current rate, you offer liquidity to receivers of fixed rates, resulting in you paying rates if the trade is executed.`,
  totalProvision: `The total swap notional amount you offer as a liquidity provider for both payers and receivers.`,
  receiverProvision: `The maximum notional value you can provide to receivers, also representing the maximum notional payer position you can accumulate as a liquidity provider.`,
  payerProvision: `The maximum notional amount you can provide to payers, also representing the maximum notional receiver position you can accumulate as a liquidity provider.`,
  lpMarginRequired: `For liquidity providers, the minimum margin needed to cover both trading and liquidity provision. Below this threshold, further liquidity addition and collateral withdrawals are disallowed until margins are restored. Trading is still permitted if margin is above the Initial Margin Requirement (IMR).`,
  netRiskDirection: `Indicates the net positioning of your portfolio based on the net DV01 of contracts across maturities with the same underlying rate. Payer: Your portfolio stands to benefit from increasing interest rates. Receiver: Your portfolio stands to benefit from decreasing interest rates.`,
  netDV01: `Represents the overall sensitivity of your portfolio to a one basis point (0.01%) change in interest rates. In other words, it measures the P&L impact of rate movements on your entire position. Calculated by aggregating the DV01 values of futures across maturities sharing the same underlying rate.`,
  profitAndLoss: `Stands for "Profit and Loss". It reflects the difference in value of your futures position due to changes in the current market fixed rates compared to the fixed rate at trade inception, considering the cumulative floating rate observed and trading fees. This is essentially marking your position at market prices.`,
  maturity: `The date on which the futures contract terminates. Obligations are settled based on the cumulative floating rate up to that point through a final margin adjustment. This date is typically standardized, often aligning with the last Friday of a specific calendar quarter or month.`,
  rateImpact: `Measures the expected movement in the future rate due to your trade, showing the difference in market rate before and after your transaction. Smaller trades generally result in a lower rate impact.`,
  futureLeverage: `The notional of the future position divided by your current margin. This ratio reflects your exposure in the future relative to your collateral.`,
  currentLeverage: `Current leverage`,
  marketLeverage: `The sum of the leverages of all the futures in the same rates market. It reflects your overall exposure to rate movements relative to your collateral.`,
  floatRate: `A variable benchmark rate that changes periodically, serving as the reference rate for settling futures for all maturities in the same market. Examples: current staking rewards, 8h perpetual futures funding.`,
  futureRate: `A fixed rate set in a futures contract for a future date. Earnings are based on the difference between this rate and the average floating rate until maturity.`,
  openInterest: `The total notional value of all open futures contracts in the market`,
  change24h: `Indicates the change in future rates over the last 24 hours, measured in basis points (bps). 1 basis point = 0.01 percentage points`,
  liquidationThreshold: 'This is the point where your current margin forces your position to close, preventing further losses. Stay vigilant to avoid liquidation.',
  initialMargin: 'This is the minimum amount needed to open or increase positions, factoring in collateral and P&L. You can\'t withdraw below this level.',
  liquidityMargin: 'The minimum collateral a liquidity provider must maintain to support their liquidity contributions. For margins below this level, the LP cannot add liquidity or withdraw existing collateral, helping ensure platform stability even in volatile markets.',
  crossChainDeposit: 'Cross-chain deposit',
  instrument: 'Instrument',
  averageRate: 'Average rate',
  liquidationRate: 'Liquidation rate',
  marginUsage: 'Margin usage',
  availableMargin: 'Available margin',
  apy: 'APY (Annual Percentage Yield) represents the yearly earnings on an investment or savings account, factoring in compound interest. It provides a comprehensive view of your potential return by considering the effect of interest compounding over a year.',
  tvl: 'Total Value Locked'
}

export default tooltips
