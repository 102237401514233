import React, {useEffect, useState} from 'react'
import {Modal} from "antd";
import {Box} from "grommet";
import {useParams, useSearchParams, useLocation} from "react-router-dom";
import {PageModal} from "../../constants";
import {MarketInfo} from "../../types";
import {MarginManagement} from "../../pages/margin-management";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {MarginTransfer} from "../../pages/margin-transfer";
import {RestrictedAccessModal} from "../restricted-access-modal";
import {getFutureByAlias} from "../../utils";
import {InvestModal} from "../../pages/earn/InvestModal";
import {WithdrawModal} from "../../pages/earn/WithdrawModal";

export const ModalsHOC = (props: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const routeParams = useParams()
  const location = useLocation();

  const routeFutureAlias = routeParams.futureAlias || ''
  const searchMarketId = searchParams.get('marketId') || ''
  const searchParamModal = searchParams.get('modal')
  const isRestrictedLocation = location.pathname === '/restricted'

  const { markets } = useProtocolData()
  const [modalName, setModalName] = useState(searchParamModal as PageModal)
  const [market, setMarket] = useState<MarketInfo>()

  useEffect(() => {
    if(isRestrictedLocation) {
      setModalName(PageModal.restrictedAccess)
    } else {
     if(modalName !== PageModal.restrictedAccess) {
       setModalName(searchParamModal as PageModal)
     }
    }
  }, [modalName, searchParamModal, isRestrictedLocation]);

  // TODO: refactor using useFutureAlias hook
  useEffect(() => {
    if(markets.length > 0) {
      const data = getFutureByAlias(routeFutureAlias, markets)
      if(data) {
        setMarket(data.market)
      }
    }
  }, [routeFutureAlias, markets]);

  const handleCancel = () => {
    const newSearch = new URLSearchParams();
    setSearchParams(newSearch)
  }

  const isOpen = !!modalName

  const modalStyles = {
    mask: {
      backdropFilter: 'blur(4px)',
    },
  }

  return <Box>
    {props.children}
    <Modal
      className={'antd_modal_container'}
      title={null}
      footer={null}
      closable={false}
      width={'620px'}
      open={isOpen}
      styles={modalStyles}
      onCancel={handleCancel}
    >
      {modalName === PageModal.restrictedAccess &&
          <RestrictedAccessModal />
      }
      {modalName === PageModal.margin &&
          <MarginManagement
              marketId={searchMarketId || market?.descriptor.id}
              onClose={handleCancel}
          />
      }
      {modalName === PageModal.transfer &&
          <MarginTransfer
              marketId={searchMarketId || market?.descriptor.id}
              onClose={handleCancel}
          />
      }
      {modalName === PageModal.earnInvest &&
          <InvestModal
              onClose={handleCancel}
          />
      }
      {modalName === PageModal.earnRedeem &&
          <WithdrawModal
              onClose={handleCancel}
          />
      }
    </Modal>
  </Box>
}
