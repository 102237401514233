import React from 'react'
import {Box} from "grommet";
import {TradeButtonProps} from "../common";
import {PrimaryButton} from "../../../components";
import {useConnect, useDisconnect} from "wagmi";
import {connect} from "wagmi/actions";
import config from "../../../config";

export const ConnectWalletButton = (props: TradeButtonProps) => {
  const { connectors } = useConnect()
  const { disconnectAsync } = useDisconnect()

  const metamaskConnector = connectors
    .find((item) => item.id.toLowerCase() === 'metamask')

  const onClick = async () => {
    if(metamaskConnector) {
      await disconnectAsync()
      const res = await connect({
        connector: metamaskConnector,
        chainId: config.chainId,
      })
      console.log('Wallet connected:', res)
    }
  }

  return <Box>
    <PrimaryButton
      disabled={!metamaskConnector}
      text={'Connect Wallet'}
      onClick={onClick}
    />
  </Box>
}
