import React from 'react'
import {Box, Text} from 'grommet'
import {ConnectWallet} from "./ConnectWallet";
import styled from "styled-components";
import {ReactComponent as RhoLogo} from '../../assets/images/rho_logo.svg';
import {HeaderMenu} from "./HeaderMenu";
import {getConfigMode} from "../../config";
import {NetworkTag} from "./NetworkTag";
import {NetworkSelect} from "./NetworkSelect";
import {HeaderDeposit} from "../header-deposit";
import {useAccount} from "wagmi";

const RhoTitle = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  font-family: Rubik, sans-serif;
`

const HeaderContainer = styled(Box)`
  z-index: 10;
  min-width: 700px;
`

const VerticalDelimiter = styled(Box)`
    width: 1px;
    height: 32px;
    background: #2D2E43;
`

export const Header = () => {
  const { isConnected } = useAccount()

  return <HeaderContainer
    justify={'center'}
    border={{ side: 'bottom', color: '#2D2E43' }}
    style={{ minWidth: '1100px' }}
  >
    <Box margin={'0px 16px'} justify={'between'} align={'center'} direction={'row'}>
      <Box direction={'row'}>
        <Box direction={'row'} align={'center'} gap={'16px'}>
          <RhoLogo width={'18px'} height={'28px'} />
          <Box direction={'row'} gap={'8px'}>
            <Box direction={'row'} align={'center'} gap={'8px'}>
              <RhoTitle color={'accentWhite'}>Rho Protocol</RhoTitle>
              <Box>
                <Box pad={'2px 3px'} border={{ color: '#4852FF' }} round={'3px'}>
                  <Text size={'10px'} weight={500} color={'#E4E4E8'}>BETA</Text>
                </Box>
              </Box>
            </Box>
            {getConfigMode() === 'testnet' && <NetworkTag />}
          </Box>
        </Box>
        <Box margin={{ left: '40px' }}>
          <HeaderMenu />
        </Box>
      </Box>
      <Box direction={'row'} gap={'20px'} align={'center'}>
        {(isConnected) && <HeaderDeposit />}
        {(isConnected) && <VerticalDelimiter margin={{ left: '14px' }} />}
        <NetworkSelect />
        <VerticalDelimiter />
        <ConnectWallet />
      </Box>
    </Box>
  </HeaderContainer>
}
