import {Box, Table, TableBody, TableCell, TableRow, Text} from "grommet";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {bnToDecimal, prepareFormNumber} from "../../utils";
import {ManageMarginProps} from "./index";
import {MenuProps} from 'antd';
import {
  QuestionMark,
  FormErrorMessage,
  MarketIcon, ValuesChangeBadge, TokenAmountInput
} from "../../components";
import { Select as RhoSelect } from '../../components'
import {RhoSteps} from "../../components/steps";

const TableContainer = styled(Table)`
    tr td:nth-child(1) {
        width: 180px;
    }
    tr td:nth-child(2) {
      width: 400px;
    }
    tr {
        td {
            text-align: left;
            padding: 0;
        }
    }
`

export const MarginTransferRequest = (props: ManageMarginProps) => {
  const {
    markets = [],
    market,
    underlyingName,
    fromTokenBalance,
    fromTokenDecimals,
    formValues,
    formError,
    withdrawableMargin,
    destinationMarket,
    isTransferInProgress,
    onChangeFormValue
  } = props

  const underlyingDecimals = market.descriptor.underlyingDecimals
  const [selectedTokenName, setSelectedTokenName] = useState(underlyingName)

  useEffect(() => {
    setSelectedTokenName(underlyingName)
  }, [underlyingName]);

  const sourceMarketsSelectOptions: MenuProps['items'] = markets
    .map((market) => {
      return {
        key: market.descriptor.id,
        label: <Box
          direction={'row'}
          gap={'8px'}
          align={'center'}
        >
          <MarketIcon name={market.descriptor.sourceName} size={'30px'} iconSize={'15px'} />
          <Text>{market.descriptor.sourceName} {market.descriptor.instrumentName}</Text>
        </Box>,
      }
    })

  const destMarketsSelectOptions: MenuProps['items'] = markets
    .map((market) => {
      return {
        key: market.descriptor.id,
        label: <Box
          direction={'row'}
          gap={'8px'}
          align={'center'}
        >
          <MarketIcon name={market.descriptor.sourceName} size={'30px'} iconSize={'15px'} />
          <Text>{market.descriptor.sourceName} {market.descriptor.instrumentName}</Text>
        </Box>,
      }
    })

  const valueFrom = withdrawableMargin
  const valueTo = valueFrom.sub(prepareFormNumber(formValues.amount, fromTokenDecimals))
  const inputBottomPanel = <Box pad={'0 10px 0'}>
    <Box background={'#272835'} width={'100%'} height={'1px'} />
    <Box direction={'row'} justify={'between'} align={'center'}>
      <Box direction={'row'} margin={{ top: '8px', bottom: '8px' }} gap={'10px'} align={'center'}>
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>AVAILABLE</Text>
          <QuestionMark tooltipId={'margin_transfer_available'} tooltipText={'Market margin'} />
        </Box>
        <ValuesChangeBadge
          from={valueFrom}
          to={valueTo}
          decimals={fromTokenDecimals}
          name={selectedTokenName}
          showName={false}
          fontSize={'12px'}
        />
      </Box>
      <Box direction={'row'} gap={'4px'}>
        {[25, 50, 75, 100].map((value) => {
          return <Box key={value} background={'optionBg'} round={'4px'} pad={'4px 7px'} onClick={() => {
            try {
              const amount = bnToDecimal(withdrawableMargin, underlyingDecimals)
              const amountDecimal = amount.mul(value / 100)
              onChangeFormValue({ 'amount': amountDecimal.toString() })
            } catch (e) {
              console.error('Failed to format amount', fromTokenBalance)
            }
          }}>
            <Text size={'12px'} color={'accentWhite2'}>{value}%</Text>
          </Box>
        })}
      </Box>
    </Box>
  </Box>

  return <Box>
    <TableContainer>
      <TableBody>
        <TableRow>
          <TableCell>
            <Box direction={'row'} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>Source market</Text>
              <QuestionMark tooltipId={'margin_transfer_source_market'} tooltipText={'Source market'} />
            </Box>
          </TableCell>
          <TableCell>
            <RhoSelect
              height={'60px'}
              width={'400px'}
              items={sourceMarketsSelectOptions}
              onClick={(id) => {
                props.setMarketId(id)
                props.onChangeFormValue({ currentStep: 'approve' })
              }}
              placeholder={
                <Box width={'100%'} direction={'row'} gap={'8px'} align={'center'}>
                  <MarketIcon name={market.descriptor.sourceName} size={'34px'} iconSize={'20px'} />
                  <Text color={'accentWhite2'}>
                    {market.descriptor.sourceName} {market.descriptor.instrumentName}
                  </Text>
                </Box>
              }
              onUpdate={() => {}}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '24px' }}>
              <Text color={'textSecondary'}>Destination market</Text>
              <QuestionMark tooltipId={'margin_transfer_dest_market'} tooltipText={'Destination market'} />
            </Box>
          </TableCell>
          <TableCell>
            <Box margin={{ top: '24px' }}>
              <RhoSelect
                height={'60px'}
                width={'400px'}
                items={destMarketsSelectOptions}
                onClick={(id) => {
                  props.setDestinationMarketId(id)
                  props.onChangeFormValue({ currentStep: 'approve' })
                }}
                placeholder={
                  destinationMarket &&
                    <Box width={'100%'} direction={'row'} gap={'8px'} align={'center'}>
                        <MarketIcon name={destinationMarket.descriptor.sourceName} size={'34px'} iconSize={'20px'} />
                        <Text color={'accentWhite2'}>
                          {destinationMarket.descriptor.sourceName} {destinationMarket.descriptor.instrumentName}
                        </Text>
                    </Box>
                }
                onUpdate={() => {}}
              />
              {formError && formError.field === 'destinationMarket' &&
                  <FormErrorMessage formError={formError} />
              }
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box direction={'row'} margin={{ top: '24px' }} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>Amount</Text>
              <QuestionMark tooltipId={'margin_mgmt_amount'} tooltipText={'Amount'} />
            </Box>
          </TableCell>
          <TableCell>
            <Box margin={{ top: '24px' }}>
              <Box>
                <TokenAmountInput
                  id={'margin_mgmt_amount'}
                  value={formValues.amount}
                  status={formError && formError.field === 'notional' ? 'error' : ''}
                  options={[{ text: underlyingName }]}
                  // width={'300px'}
                  bottompanel={inputBottomPanel}
                  onChange={(value) => onChangeFormValue({
                    amount: (value || 0).toString(),
                    currentStep: 'approve'
                  })}
                />
              </Box>
              {formError && formError.field === 'amount' &&
                  <FormErrorMessage formError={formError} />
              }
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box direction={'row'} margin={{ top: '32px' }} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>Steps</Text>
              <QuestionMark tooltipId={'margin_transfer_steps'} tooltipText={'Steps'} />
            </Box>
          </TableCell>
          <TableCell>
            <Box margin={{ top: '32px', left: '8px' }} width={'calc(100% - 32px)'}>
              <RhoSteps
                items={[
                  {
                    text: <Box direction={'row'} align={'center'} gap={'8px'}>
                      <Text color={'textSecondary'}>Approve</Text>
                      <QuestionMark tooltipId={'margin_manage_steps_approve'} tooltipText={'Approve'} />
                    </Box>,
                    isActive: formValues.currentStep === 'approve',
                    isInProgress: formValues.currentStep === 'approve' && isTransferInProgress,
                    status: ['withdraw', 'deposit', 'done'].includes(formValues.currentStep) ? 'done' : 'default'
                  },
                  {
                    text: <Box direction={'row'} align={'center'} gap={'8px'}>
                      <Text color={'textSecondary'}>Withdraw</Text>
                      <QuestionMark tooltipId={'margin_manage_steps_withdraw'} tooltipText={'Withdraw'} />
                    </Box>,
                    isActive: formValues.currentStep === 'withdraw',
                    isInProgress: formValues.currentStep === 'withdraw' && isTransferInProgress,
                    status: ['deposit', 'done'].includes(formValues.currentStep) ? 'done' : 'default'
                  },
                  {
                    text: <Box direction={'row'} align={'center'} gap={'8px'}>
                      <Text color={'textSecondary'}>Deposit</Text>
                      <QuestionMark tooltipId={'margin_manage_steps_deposit'} tooltipText={'Deposit'} />
                    </Box>,
                    isActive: formValues.currentStep === 'deposit',
                    isInProgress: formValues.currentStep === 'deposit' && isTransferInProgress,
                    status: ['done'].includes(formValues.currentStep) ? 'done' : 'default'
                  }
                ]}
              />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <Box margin={{ top: '32px' }}></Box>
        </TableRow>
      </TableBody>
    </TableContainer>
  </Box>
}
