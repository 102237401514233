import React from 'react';
import { Grommet } from "grommet";
import { BrowserRouter } from "react-router-dom";
import { darkTheme } from "./theme/grommet";
import { antdTheme } from "./theme/antd";
import { AppRoutes } from "./Routes";
import './modules/i18n'
import { WagmiConfig } from 'wagmi'
import {wagmiConfig} from "./modules/wagmi";
import { ToastContainer } from 'react-toastify';
import {isMainnet, sentryDSN} from "./config";
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import './index.css'
import { PriceProvider } from "./providers/PriceProvider";
import { RateProvider } from './providers/FloatRateProvider';
import {ConfigProvider} from "antd";
import './modules/chartjs'
import {ProtocolDataProvider} from "./providers/ProtocolDataProvider";
import {Helmet} from "react-helmet";
import {configureSentry} from "./modules/sentry";
import {VaultDataProvider} from "./providers/VaultDataProvider";

if(sentryDSN) {
  console.log('Sentry: configure with DSN', sentryDSN)
  configureSentry(sentryDSN)
} else {
  console.log('Sentry is disabled in config. Set [REACT_APP_SENTRY_DSN] variable to enable.')
}

function App() {
  return (
    <Grommet theme={darkTheme} themeMode={'dark'} full>
      <ConfigProvider theme={antdTheme}>
        <BrowserRouter>
          <WagmiConfig config={wagmiConfig}>
            <PriceProvider>
              <RateProvider>
                <ProtocolDataProvider>
                  <VaultDataProvider>
                    <Helmet>
                      <title>{isMainnet() ? 'Rho Protocol' : '[Testnet] Rho Protocol'}</title>
                    </Helmet>
                    <AppRoutes />
                  </VaultDataProvider>
                </ProtocolDataProvider>
              </RateProvider>
            </PriceProvider>
          </WagmiConfig>
        </BrowserRouter>
      </ConfigProvider>
      <ToastContainer theme={'dark'} closeOnClick={false} autoClose={5000} />
    </Grommet>
  );
}

export default App;
