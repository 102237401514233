import {useEffect, useState} from "react";
import {getSquidChains, getSquidTokens, SquidToken} from "../api/squid";

export const useSquidData = (params: {
  enabled?: boolean
}) => {
  const [tokens, setSquidTokens] = useState<SquidToken[]>([])

  const loadData = async () => {
    try {
      const tokensData = await getSquidTokens()
      setSquidTokens(tokensData)
      console.log('Squid tokens loaded:', tokensData)
      const data = await getSquidChains()
      console.log('Squid chains loaded:', data)
    } catch (e) {
      console.error('Failed to get squid data', e)
    }
  }

  useEffect(() => {
    if(params.enabled) {
      loadData()
    }
  }, [params.enabled]);

  return {
    tokens
  }
}
