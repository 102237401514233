import { Box, Text } from "grommet";
import React, {ReactNode, useEffect, useState} from "react";
import styled from "styled-components";
import {OpType, RiskDirection} from "../types";
import moment from "moment/moment";
import { FloatRate } from "../providers/FloatRateProvider";
import {getIconByName, RightArrow} from "./Icon";
import {BigNumber} from "ethers";
import {getNumberText, Number, NumberProps} from "./Number";
import {QuestionMark} from "./QuestionMark";
import {WidgetContainer} from "./widgets";

const TriangleUp = styled(Box)`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent #5ABF7D transparent;
`

const TriangleDown = styled(Box)`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #DA4242 transparent transparent transparent;
`

export const DashedValueBadge = (props: {
  text: string;
  value: string;
  tooltipId: string;
  tooltipText: string;
  color?: string
}) => {
  const { color= 'textSecondary' } = props
  return <Box border={{ style: 'dashed', color: '#3D3F5C' }} round={'3px'} pad={'6px 11px'}>
    <Text weight={500} color={color}>{props.value}</Text>
    <Box direction={'row'} align={'center'} gap={'4px'}>
      <Text color={'textSecondary'} size={'12px'}>{props.text}</Text>
      <QuestionMark tooltipId={props.tooltipId} tooltipText={props.tooltipText} />
    </Box>
  </Box>
}

export const DashedContainer = (props: { children: ReactNode }) => {
  return <Box border={{ style: 'dashed', color: '#3D3F5C' }} round={'4px'} pad={'6px 11px'}>
    {props.children}
  </Box>
}

export const TickerBadge = (props: { value: BigNumber, coloredValue?: boolean }) => {
  const { value, coloredValue = true } = props

  const zero = BigNumber.from(0)

  let color = 'text'
  if (value.gt(zero)) {
    color = 'ratePositive'
  } else if (value.lt(zero)) {
    color = 'rateNegative'
  }
  return <Box direction={'row'} align={'center'} gap={'3px'}>
    {!value.eq(zero) &&
      value.gt(zero)
      ? <TriangleUp />
      : <TriangleDown />
    }
    <Text color={coloredValue ? color : 'text'}>
      <Number value={value} decimals={16} name={'%'} showName={true} />
    </Text>
  </Box>
}

export interface RateBadgeProps {
  id?: string
  value: number | null;
  color?: string
  fractionalDigits?: number
  size?: string;
  suffix?: string
  prefix?: string
  weight?: number
  dynamicColorByDelta?: boolean
  dynamicColorByAbsValue?: boolean
}

export const RateBadge = (props: RateBadgeProps) => {
  const {
    id,
    value,
    fractionalDigits = 0,
    size = '16px',
    suffix = '',
    weight = 400,
    prefix = '',
    dynamicColorByDelta = true,
    dynamicColorByAbsValue = false
  } = props;

  const [delta, setDelta] = useState(value === null ? 0 : value)
  const [lastValue, setLastValue] = useState(value)

  useEffect(() => {
    if(value !== null && lastValue !== null) {
      setDelta(value - lastValue)
    }
    setLastValue(value)
  }, [value]);

  useEffect(() => {
    if(id) {
      setDelta(0)
    }
  }, [id]);

  let color = props.color || 'accentWhite2';
  if(dynamicColorByDelta) {
    if (delta > 0) {
      color = 'ratePositive';
    } else if (delta < 0) {
      color = 'rateNegative';
    }
  } else if (dynamicColorByAbsValue && value) {
    if (value > 0) {
      color = 'ratePositive';
    } else if (value < 0) {
      color = 'rateNegative';
    }
  }


  return (
    <Box direction={'row'} align={'center'} gap={'3px'}>
      <Text color={color} size={size} weight={weight}>
        {prefix}{(value || 0).toFixed(fractionalDigits) + suffix}
      </Text>
    </Box>
  )
}

export const FloatRateBadge = (props: {
  floatRate: FloatRate | null,
  coloredValue?: boolean
}) => {
  const { floatRate, coloredValue = true } = props;

  let color = 'text';
  let displayValue: string = '--';

  if (floatRate !== null) {
    displayValue = floatRate.rate.toFixed(4) + '%';

    if(floatRate.tag === 'funding') {
      displayValue += ` (${(floatRate.rate * 365 * 3).toFixed(4)}% p.a.)`
    }

    if (floatRate.rate > 0) {
      color = 'ratePositive';
    } else if (floatRate.rate < 0) {
      color = 'rateNegative';
    }
  }

  return (
    <Box direction={'row'} align={'center'} gap={'3px'}>
      {floatRate !== null && floatRate.delta !== 0 && (
        floatRate.delta > 0
          ? <TriangleUp />
          : <TriangleDown />
      )}
      <Text color={coloredValue ? color : 'text'}>
        {displayValue}
      </Text>
    </Box>
  )
}

export const RandomColorTickerBadge = (props: { value: number, coloredValue?: boolean }) => {
  const { coloredValue = true } = props

  let randomValueForColoredRate = Math.random() < 0.5 ? -1 : 1;

  let color = 'text'
  if (randomValueForColoredRate > 0) {
    color = 'ratePositive'
  } else if (randomValueForColoredRate < 0) {
    color = 'rateNegative'
  }
  return <Box direction={'row'} align={'center'} gap={'3px'}>
    {randomValueForColoredRate !== 0 &&
      randomValueForColoredRate > 0
      ? <TriangleUp />
      : <TriangleDown />
    }
    <Text color={coloredValue ? color : 'text'}>
      {Math.abs(props.value)} %
    </Text>
  </Box>
}

export const OpTypeBadge = (props: { opType: OpType }) => {
  const { opType } = props
  let bgColor = '#1C3838'
  let textColor = '#70D693'

  if (opType === 'borrowing') {
    bgColor = '#223459'
    textColor = '#00AAFF'
  } else if (opType === 'staking') {
    bgColor = '#492441'
    textColor = '#E35FAE'
  }

  return <Box background={bgColor} pad={'1px 9px'} round={'3px'}>
    <Text color={textColor} weight={500} size={'11px'}>
      {opType}
    </Text>
  </Box>
}

export const MaturityBadge = (props: {
  value: number,
  size?: string,
  showDaysLeft?: boolean
}) => {
  const { value, size = 'default', showDaysLeft = true } = props

  const isMatured = value < Date.now()
  const daysDiff = moment(value).diff(moment(), 'days')
  const daysOffset = moment().add(daysDiff, 'day')
  const hoursDiff = moment(value).diff(daysOffset, 'hours')
  const minutesDiff = moment(value).diff(daysOffset, 'minutes')

  return <Box>
    <Text size={size}>{moment(value).format('DD-MM-YYYY')}</Text>
    {isMatured &&
      <Box>
          <Text size={'12px'} color={'positiveValue'}>Matured</Text>
      </Box>
    }
    {(showDaysLeft && !isMatured) &&
        <Text color={'textSecondary'} size={'12px'} style={{ lineHeight: '22px' }}>
          {`${daysDiff}d ${hoursDiff}h ${hoursDiff === 0 ? minutesDiff+'m' : ''} left`}
        </Text>
    }
  </Box>
}

export interface DynamicValueProps {
  value: BigNumber,
  decimals: number
  name?: string
  fontSize?: string
  weight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number;
  showName?: boolean
}

export const DynamicValue = (props: DynamicValueProps) => {
  const {
    value,
    decimals,
    name,
    fontSize = '14px',
    weight = 'normal',
    showName = false
  } = props

  let color = 'text'
  let sign = ''
  if (value.gt(0)) {
    sign = '+ '
    color = 'positiveValue'
  } else if (value.lt(0)) {
    color = 'negativeValue'
  }

  return <Box direction={'row'} align={'baseline'} gap={'2px'}>
    <Text color={color} size={fontSize} weight={weight} style={{ whiteSpace: 'nowrap' }}>
      {sign}
    </Text>
    <Text color={color} size={fontSize} weight={weight} style={{ whiteSpace: 'nowrap' }}>
      <Number fontColor={color} value={value} decimals={decimals} name={name} showName={showName} fontSize={fontSize} />
    </Text>
  </Box>
}

export const RiskDirectionBadge = (props: { text: RiskDirection | null }) => {
  return <Box
    width={'108px'}
    pad={'4px 16px'}
    justify={'center'}
    align={'center'}
    background={'#343851'}
    round={'3px'}
  >
    <Text
      color={'text'}
      weight={500}
      size={'16px'}>
      {props.text || 'None'}
    </Text>
  </Box>
}

export const CoinBadge = (props: { name: string }) => {
  const { name= 'usdc' } = props

  const { icon: minorIcon } = getIconByName(name)

  return <Box
    direction={'row'}
    align={'center'}
    justify={'center'}
    gap={name.toLowerCase() === 'usdc' ? '2px' : '8px'}
  >
    <Box
      width={'14px'}
      height={'14px'}
      round={'14px'}
    >
      {minorIcon}
    </Box>
    <Text color={'textSecondary'} size={'12px'}>{name}</Text>
  </Box>
}

export const ValuesChangeBadge = (props: {
  from: BigNumber;
  to: BigNumber | null,
  decimals: number,
  name?: string
  showName?: boolean
  fontSize?: string
  arrowIconSize?: number | string
}) => {
  const { from, to, decimals, name, showName = false, fontSize } = props

  let fromProps: NumberProps = {
    value: from,
    decimals,
    name,
    fontSize,
  }
  const fromStr = getNumberText(fromProps)

  let toNumber
  let toStr = ''
  if(to) {
    const toProps = {
      value: to,
      decimals,
      name,
      showName,
      fontSize,
      fontColor: 'accentWhite2'
    }
    toStr = getNumberText(toProps)
    toNumber = <Number {...toProps} />
  }

  fromProps.showName = !to || (fromStr === toStr)
  const fromNumber = <Number {...fromProps} />

  if(!to || (fromStr === toStr)) {
    return <Box>
      {fromNumber}
    </Box>
  }

  return <Box direction={'row'} align={'center'} gap={'2px'}>
    {fromNumber}
    <Box>
      <RightArrow size={props.arrowIconSize} isPositive={from.lt(to)} />
    </Box>
    {toNumber}
  </Box>
}

export interface NotificationBadgeProps {
  type?: 'default' | 'warning' | 'error'
  children?: ReactNode
  title?: string
  description?: string | ReactNode
}

export const NotificationBadge = (props: NotificationBadgeProps) => {
  const { type} = props
  let backgroundColor = '#262D42'
  let borderColor = '#406ABF'
  let textColor = 'white'

  if(type === 'warning') {
    backgroundColor = '#3C372A'
    borderColor = '#EDC75E'
    textColor = '#EDC75E'
  }

  return <Box
    width={'100%'}
    pad={'6px 13px'}
    round={'6px'}
    background={backgroundColor}
    justify={'center'}
    gap={'3px'}
    border={{ style: 'dashed', size: '1px', color: borderColor }}
  >
    {props.children &&
      props.children
    }
    {props.title &&
      <Text size={'14px'} color={textColor} weight={500}>{props.title}</Text>
    }
    {(props.description && typeof props.description === 'string')
      ? <Text color={textColor}>{props.description}</Text>
      : props.description
    }
  </Box>
}

export interface TagOption {
  id: string
  text: string
}

const TagOptionElement = styled(Box)<{ isActive: boolean }>`
    border-radius: 3px;
    padding: 2px 10px;
    min-width: 50px;

    ${props => props.isActive &&
      `
      
      `
    }

    &:hover {
        background: #2E2E38;
        span {
            color: #D6D6DC;
        }
    }
`

export const SmallTagsOptions = (props: {
  items: TagOption[]
  activeItemId: string
  onSelect: (id: string) => void
}) => {
  const { items, activeItemId } = props
  return <Box direction={'row'} gap={'4px'}>
    {items.map(item => {
      const {id, text} = item
      const isActive = id === activeItemId
      const color = isActive ? 'textHeader' : 'labelInactive'
      return <TagOptionElement
        key={item.id}
        isActive={isActive}
        background={isActive ? 'inputBgLight' : 'transparent'}
        align={'center'}
        justify={'center'}
        onClick={() => props.onSelect(item.id)}
      >
        <Text color={color}>{text}</Text>
      </TagOptionElement>
    })}
  </Box>
}

export const InfoBadge = (props: {
  width?: string
  title: string | React.ReactNode
  description: string
  tooltip: string
  icon?: ReactNode
}) => {
  const { width = 'unset', title, description, tooltip, icon } = props

  return <WidgetContainer
    width={width}
    background={'widgetBg'}
    align={'start'}
    justify={'between'}
    gap={'4px'}
    style={{ padding: '12px' }}
  >
    {icon &&
      <Box margin={{ bottom: '2px' }}>
        {icon}
      </Box>
    }
    {typeof title === 'string' ? <Text size={'18px'}>{title}</Text> : title}
    <Box direction={'row'} align={'center'} gap={'4px'}>
      <Text color={'textSecondary'}>{description}</Text>
      <QuestionMark tooltipId={tooltip.slice(0, 12)} tooltipText={tooltip} />
    </Box>
  </WidgetContainer>
}
