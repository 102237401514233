import {Decimal} from "decimal.js";
import {FutureInfo, FutureOpenPosition, MarketPortfolio, RiskDirectionType} from "../types";
import {BigNumber} from "ethers";

export interface PotentialPosition {
  futureId: string
  notional: Decimal
  riskDirection: RiskDirectionType
}

export class RateMathBase {
  public readonly SECONDS_PER_YEAR = 60 * 60 * 24 * 365
  public readonly basicPoint = new Decimal(0.0001)
  public readonly percent = new Decimal(0.01)

  public projectionOfAccruedInterestDiff(
    rate1: Decimal,
    rate2: Decimal,
    secondsToMaturity: number
  ) {
    return new Decimal(0)
  }

  public calcThreshold(
    currentRate: Decimal,
    delta: Decimal,
    direction: RiskDirectionType,
    secondsToMaturity: number,
    marginRequirementSecondsFloor: number

  ): Decimal {
    const deltaSign = direction === RiskDirectionType.PAYER ? -1 : 1
    const worstCaseRate = currentRate.add(delta.mul(deltaSign))
    const timeToMaturity = Math.max(secondsToMaturity, marginRequirementSecondsFloor)
    return this.projectionOfAccruedInterestDiff(currentRate, worstCaseRate, timeToMaturity)
  }

  public calcMaxAdditionalNotional(
    currentMargin: Decimal,
    currentRate: Decimal,
    delta: Decimal,
    secondsToMaturity: number,
    marginRequirementSecondsFloor: number,
    portfolio?: MarketPortfolio,
    openPosition?: FutureOpenPosition,
  ) {
    const direction = openPosition ? openPosition.riskDirection : RiskDirectionType.PAYER
    const threshold = this.calcThreshold(currentRate, delta, direction, secondsToMaturity, marginRequirementSecondsFloor)
    const initialMarginThreshold = portfolio ? new Decimal(portfolio.marginState.initialMarginThreshold.toString()).div(10 ** portfolio.descriptor.underlyingDecimals) : new Decimal(0)

    return (currentMargin.sub(initialMarginThreshold)).div(threshold)
  }

  public rebaseFloatTokenToNotional(floatTokenAmount: Decimal, floatIndex: Decimal) {
    return floatTokenAmount
  }

  public calcMaxLeverageWithPortfolio(
    futureId: string,
    futures: FutureInfo[],
    futureOpenPositions: FutureOpenPosition[],
    delta: Decimal,
    currentMargin: Decimal,
    openPositionNotional: Decimal,
    currentRate: Decimal,
    secondsToMaturity: number,
    marginRequirementSecondsFloor: number,
    hedgeFactor: Decimal,
    direction: RiskDirectionType,
    portfolio: MarketPortfolio,
    underlyingDecimals: number,
    openPosition?: FutureOpenPosition,
  ) {
    const currentPositionDirection = openPosition ? openPosition.riskDirection : RiskDirectionType.PAYER

    let imrWithUnwindedCurrentFuture = new Decimal(portfolio.marginState.initialMarginThreshold.toString()).div(10 ** portfolio.descriptor.underlyingDecimals)
    let imrWithUnwindedCurrentFutureAndOppositeHedge = imrWithUnwindedCurrentFuture

    if (openPosition && openPosition.notional.gt(0)) {
      const oppositeOpenPositionDirection = currentPositionDirection === RiskDirectionType.RECEIVER ? RiskDirectionType.PAYER : RiskDirectionType.RECEIVER

      const unwindingPosition: PotentialPosition = {
        futureId: futureId,
        notional: new Decimal(openPosition.notional.toString()).div(10 ** portfolio.descriptor.underlyingDecimals),
        riskDirection: oppositeOpenPositionDirection
      }

      imrWithUnwindedCurrentFuture = this.calcMarginThreshold(futures, futureOpenPositions, delta, hedgeFactor, underlyingDecimals, marginRequirementSecondsFloor, unwindingPosition)
      imrWithUnwindedCurrentFutureAndOppositeHedge = this.calcMarginThreshold(futures, futureOpenPositions, delta, hedgeFactor, underlyingDecimals, marginRequirementSecondsFloor, unwindingPosition, direction)
    }

    const freeMarginWithUnwindedCurrentFuture = currentMargin.sub(imrWithUnwindedCurrentFuture)

    const kWithoutHedgeFactor = this.calcThreshold(currentRate, delta, direction, secondsToMaturity, marginRequirementSecondsFloor)
    const kWithHedgeFactor = kWithoutHedgeFactor.mul(hedgeFactor)

    const maxNotionalWithoutHedge = freeMarginWithUnwindedCurrentFuture.div(kWithoutHedgeFactor)
    let finalMaxNotional = maxNotionalWithoutHedge; // When considering a direction aligned with the portfolio's

    if (direction !== portfolio.marginState.riskDirection) {
      // When considering a direction opposite to the portfolio's direction
      const maxNotionalWithHedge = freeMarginWithUnwindedCurrentFuture.div(kWithHedgeFactor)

      const potentialPositionWithMaxNotionalWithHedge = {
        futureId: futureId,
        notional: direction === currentPositionDirection
            ? maxNotionalWithHedge.abs().sub(openPositionNotional.abs())
            : maxNotionalWithHedge.abs().add(openPositionNotional.abs()),
        riskDirection: direction
      };

      const portfolioRiskDirectionWithMaxNotionalWithHedge = this.calcPortfolioRiskDirection(portfolio.futures, portfolio.futureOpenPositions, underlyingDecimals, potentialPositionWithMaxNotionalWithHedge);

      if (portfolioRiskDirectionWithMaxNotionalWithHedge !== direction) {
        // If position with maxNotionalWithHedge does not change portfolio direction
        finalMaxNotional = maxNotionalWithHedge;
      } else {
        // If position with maxNotionalWithHedge changes portfolio direction
        const updatedFreeMargin = currentMargin.sub(imrWithUnwindedCurrentFutureAndOppositeHedge)
        finalMaxNotional = updatedFreeMargin.div(kWithoutHedgeFactor)
      }
    }

    const maxLeverage = finalMaxNotional.div(currentMargin);

    let result = maxLeverage.abs()

    const currentLeverage = openPositionNotional.div(currentMargin)
    result = currentLeverage.abs().gt(result) ? currentLeverage : result // Current leverage must always be available

    return result.toNumber()
  }

  public calcFutureMaxLeverage(
      currentRate: Decimal,
      delta: Decimal,
      secondsToMaturity: number,
      marginRequirementSecondsFloor: number,
      direction: RiskDirectionType,
  ) {
    const threshold = this.calcThreshold(currentRate, delta, direction, secondsToMaturity, marginRequirementSecondsFloor)

    let maxLeverage = new Decimal(1).div(threshold).abs().toNumber()

    // Rounding
    if (maxLeverage < 10) {
      maxLeverage =  Math.floor(maxLeverage);
    } else if (maxLeverage < 50) {
      maxLeverage =  5 * Math.floor(maxLeverage / 5);
    } else {
      maxLeverage = 10 * Math.floor(maxLeverage / 10);
    }

    return maxLeverage
  }

  getDv01(
    notional: Decimal,
    rate: Decimal,
    secondsToMaturity: number
  ) {
    const projection = this.projectionOfAccruedInterestDiff(
      rate.sub(this.basicPoint),
      rate.add(this.basicPoint),
      secondsToMaturity
    )
    return (notional.mul(projection.abs())).div(2)
  }

  getDv100(
    notional: Decimal,
    rate: Decimal,
    secondsToMaturity: number
  ) {
    const projection = this.projectionOfAccruedInterestDiff(
      rate.sub(this.percent),
      rate.add(this.percent),
      secondsToMaturity
    )
    return (notional.mul(projection.abs())).div(2)
  }

  public bnToDecimal = (value: BigNumber, underlyingDecimals: number) => {
    return new Decimal(value.toString()).div(10 ** underlyingDecimals)
  }

  public getRiskDirectionBySignFactor(value: Decimal) {
    return value.lt(0)
      ? RiskDirectionType.RECEIVER
      : RiskDirectionType.PAYER
  }

  calcPortfolioRiskDirection(
      futures: FutureInfo[],
      futureOpenPositions: FutureOpenPosition[],
      underlyingDecimals: number,
      potentialPosition?: PotentialPosition
  ) {
    let totalDv01 = new Decimal(0)
    const activeFutures = futures.filter((future) => {
      return future.termStart.add(future.termLength).mul(1000).gt(BigNumber.from(Date.now()))
    })

    for(const future of activeFutures) {
      const openPosition = futureOpenPositions.find(pos => pos.futureId === future.id)
      let notional = this.bnToDecimal(openPosition?.notional || BigNumber.from(0), underlyingDecimals)
      if(openPosition?.tokensPair.floatTokenAmount.lt(0)) {
        notional = notional.mul(-1)
      }

      if(potentialPosition && future.id === potentialPosition.futureId) {
        let signedPotentialPositionNotional = potentialPosition.notional
        if(potentialPosition.riskDirection === RiskDirectionType.RECEIVER) {
          signedPotentialPositionNotional = signedPotentialPositionNotional.mul(-1)
        }
        notional = notional.add(signedPotentialPositionNotional)
      }

      const dv01 = this.getDv01(
          notional,
          this.bnToDecimal(future.vAMMParams.currentFutureRate, 18),
          future.termStart.add(future.termLength).sub(Math.round(Date.now()/1000)).toNumber()
      )

      totalDv01 = totalDv01.add(dv01)
    }

    return this.getRiskDirectionBySignFactor(totalDv01)
  }

  calcMarginThreshold(
    futures: FutureInfo[],
    futureOpenPositions: FutureOpenPosition[],
    delta: Decimal,
    hedgeFactor: Decimal,
    underlyingDecimals: number,
    marginRequirementSecondsFloor: number,
    potentialPosition?: PotentialPosition,
    enforcedPortfolioRiskDirection? :RiskDirectionType
  ) {
    let totalMarginThreshold = new Decimal(0)

    const activeFutures = futures.filter((future) => {
      return future.termStart.add(future.termLength).mul(1000).gt(BigNumber.from(Date.now()))
    })

    const portfolioRiskDirection = enforcedPortfolioRiskDirection ?? this.calcPortfolioRiskDirection(futures, futureOpenPositions, underlyingDecimals, potentialPosition)

    for(const future of activeFutures) {
      const openPosition = futureOpenPositions.find(pos => pos.futureId === future.id)
      let notional = this.bnToDecimal(openPosition?.notional || BigNumber.from(0), underlyingDecimals)
      if(openPosition?.tokensPair.floatTokenAmount.lt(0)) {
        notional = notional.mul(-1)
      }

      if(potentialPosition && future.id === potentialPosition.futureId) {
        let signedPotentialPositionNotional = potentialPosition.notional
        if(potentialPosition.riskDirection === RiskDirectionType.RECEIVER) {
          signedPotentialPositionNotional = signedPotentialPositionNotional.mul(-1)
        }
        notional = notional.add(signedPotentialPositionNotional)
      }

      const currentRate = this.bnToDecimal(future.vAMMParams.currentFutureRate, 18)
      const riskDirection = this.getRiskDirectionBySignFactor(notional)
      const secondsToMaturity = future.termStart.add(future.termLength).sub(Math.round(Date.now()/1000)).toNumber()

      let futureThreshold = notional.mul(
        this.calcThreshold(currentRate, delta, riskDirection, secondsToMaturity, marginRequirementSecondsFloor)
      )

      if(portfolioRiskDirection !== riskDirection) {
        futureThreshold = futureThreshold.mul(hedgeFactor)
      }

      totalMarginThreshold = totalMarginThreshold.add(futureThreshold.abs())
    }

    return totalMarginThreshold
  }
}

export class LinearRateMath extends RateMathBase {
  public projectionOfAccruedInterestDiff(
    rate1: Decimal,
    rate2: Decimal,
    secondsToMaturity: number
  ) {
    const t = secondsToMaturity / this.SECONDS_PER_YEAR
    return (rate1.sub(rate2)).mul(t)
  }
}

export class CompoundingRateMath extends RateMathBase {
  public projectionOfAccruedInterestDiff(
    rate1: Decimal,
    rate2: Decimal,
    secondsToMaturity: number
  ) {
    const t = secondsToMaturity / this.SECONDS_PER_YEAR
    return ((rate1.add(1)).pow(t)).sub((rate2.add(1)).pow(t))
  }

  public rebaseFloatTokenToNotional(floatTokenAmount: Decimal, floatIndex: Decimal) {
    return floatTokenAmount.mul(floatIndex)
  }
}
