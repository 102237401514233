import {
  arbitrum,
  avalanche,
  base,
  bsc,
  linea,
  mainnet,
  optimism,
  polygon,
  mantle,
  fantom,
} from "wagmi/chains";

export const NetworksList = [
  mainnet,
  arbitrum,
  avalanche,
  optimism,
  polygon,
  base,
  bsc,
  mantle,
  fantom
]

export const BridgeTokensSymbols = [
  'USDT',
  'USDC',
  'WBTC',
  'ETH',
  'WETH',
  'BNB',
  'XRP',
  'AVAX',
  'MATIC',
  'DAI',
  'ARB',
  'OP',
  'BASE',
  'MNT',
  'ATOM',
  'FTM'
]

export const NativeSymbols = ['ETH', 'MATIC', 'FTM', 'MNT', 'BNB', 'AVAX']
