import React, {useRef, useState} from 'react'
import {Anchor, Box, Button, Text} from 'grommet'
import { connect, disconnect } from 'wagmi/actions'
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import {useConnect, useDisconnect, useAccount, useNetwork} from "wagmi";
import {ReactComponent as MetamaskLogo} from '../../assets/images/metamask_logo.svg';
import {ReactComponent as WalletConnectLogo} from '../../assets/images/wallet_connect_logo.svg';
import {ReactComponent as CopyImg} from '../../assets/images/copy.svg';
import {ReactComponent as LinkImg} from '../../assets/images/link.svg';
import {getExplorerUrl, truncateEthAddress} from "../../utils";
import {Arrow} from "../Icon";
import {WidgetContainer} from "../widgets";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {Dropdown, MenuProps, Modal} from "antd";
import {ChainName} from "../../config";

interface ModalProps {
  isOpened: boolean
  setShow: (show: boolean) => void
}

interface OptionProps {
  disabled?: boolean
  text: string
  icon: any
  description: string
  onClick: () => void
}

const ConnectOptionContainer = styled(Box)`
  background: #26262c;
  transition: background-color 250ms;
  &:hover {
    background: #2f3037;
  }
`

const ConnectWalletOption = (props: OptionProps) => {
  const { disabled } = props
  return <ConnectOptionContainer
    pad={'16px'}
    gap={'16px'}
    direction={'row'}
    round={'8px'}
    onClick={!disabled ? props.onClick : undefined}
    style={{ opacity: disabled ? 0.4 : 'unset' }}
  >
    <Box width={'38px'} height={'38px'}>
      {props.icon}
    </Box>
    <Box justify={'between'}>
      <Box>
        <Text size={'16px'} weight={500}>{props.text}</Text>
      </Box>
      <Box>
        <Text weight={400} color={'textSecondary'}>{props.description}</Text>
      </Box>
    </Box>
  </ConnectOptionContainer>
}

const CloseIcon = styled(Box)`
    transition: transform 250ms;
    &:hover {
        transform: scale(1.2);
    }
`

const ConnectModal = (props: ModalProps) => {
  const { isOpened, setShow } = props
  const { t } = useTranslation()
  const {
    open: openWeb3Modal,
  } = useWeb3Modal()
  const { open: isWeb3ModalOpen } = useWeb3ModalState()
  const { connectors } = useConnect()
  const { disconnectAsync } = useDisconnect()

  const metamaskConnector = connectors
    .find((item) => item.id.toLowerCase() === 'metamask')

  const onClickMetamask = async () => {
    if(metamaskConnector) {
      try {
        await disconnectAsync()
        const res = await connect({
          connector: metamaskConnector
        })
        console.log('Wallet connected:', res)
        setShow(false)
      } catch (e) {
        console.error('Metamask: failed to connect:', e)
      }
    }
  }

  const onClickWalletConnect = async () => {
    try {
      await openWeb3Modal()
      props.setShow(false)
    } catch (e) {
      console.error('Wallet connect error:', e)
    }
  }

  const onClickOutside = async () => {
    if(isWeb3ModalOpen) {
      // await closeWeb3Modal()
    }
    setShow(false)
  }

  const modalStyles = {
    mask: {
      backdropFilter: 'blur(4px)',
    },
  }

  return <Modal
    className={'antd_modal_container'}
    width={'420px'}
    open={isOpened}
    footer={null}
    closable={false}
    onCancel={onClickOutside}
    styles={modalStyles}
  >
    <WidgetContainer border={{ size: '1px' }}>
      <Box
        direction={'row'}
        justify={'between'}
      >
        <Text weight={500}>Connect your Wallet</Text>
        <CloseIcon onClick={() => setShow(false)}>
          <Text size={'20px'}>✕</Text>
        </CloseIcon>
      </Box>
      <Box margin={{ top: '8px' }}>
        <Text size={'12px'} style={{ lineHeight: '20px' }}>
          By connecting a wallet, you agree to Rho Protocol <Anchor href={''} color={'brandRho'}>Terms of Service</Anchor> and represent and warrant to Rho Protocol that you are not a <Anchor href={'/restricted-persons'} target={'_blank'} color={'brandRho'}>Restricted Person</Anchor>.
        </Text>
      </Box>
      <Box margin={{ top: '16px' }} gap={'8px'} pad={{ bottom: '16px' }}>
        <ConnectWalletOption
          text={t('wallet.metamask_title')}
          icon={<MetamaskLogo style={{ width: 'inherit' }} />}
          description={t('wallet.metamask_description')}
          onClick={onClickMetamask}
        />
        <ConnectWalletOption
          text={'WalletConnect'}
          icon={<WalletConnectLogo style={{ width: 'inherit' }} />}
          description={t('wallet.wc_description')}
          onClick={onClickWalletConnect}
        />
      </Box>
    </WidgetContainer>
  </Modal>
}

interface AccountButtonProps {
  isModalOpened: boolean
  setModalOpened: (open: boolean) => void
}

const ConnectedButtonContainer = styled(Button)`
  background: none;
  border-radius: 3px;
  font-family: 'Roboto',serif;
  padding: 5px 10px;
`

const ConnectedButton = () => {
  const { address, connector } = useAccount()
  const { t } = useTranslation()
  const { chain } = useNetwork()
  const [isCopied, setIsCopied] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [isOpened, setOpened] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setOpened(false));

  const onCopyAddress = async () => {
    navigator.clipboard.writeText(address || '')
    setIsCopied(true)
    clearTimeout(timeoutId)
    const id = setTimeout(() => {
      setIsCopied(false)
    }, 2000)
    setTimeoutId(id)
  }

  const onDisconnectClicked = () => {
    disconnect()
    setOpened(false)
  }

  const viewOnExplorer = () => window.open(`${getExplorerUrl()}/address/${address}`, '_blank')

  const dropdownContent = <WidgetContainer width={'220px'}>
    <Box direction={'row'} gap={'12px'} align={'center'}>
      {connector?.name === 'MetaMask' &&
          <Box><MetamaskLogo width={'20px'} height={'20px'} /></Box>
      }
      {connector?.name === 'WalletConnectLegacy' &&
          <Box><WalletConnectLogo width={'20px'} height={'20px'} /></Box>
      }
      <Box>
        <Text weight={500}>
          {truncateEthAddress(address || '')}
        </Text>
      </Box>
    </Box>
    <Box width={'128px'} margin={{ top: '8px' }}>
      <Button
        style={{ padding: '4px 0px' }}
        onClick={onDisconnectClicked}>
        {t('account.disconnect')}
      </Button>
    </Box>
    <Box margin={{ top: '16px' }} pad={{ top: '8px' }} border={{ side: 'top' }}>
      <Text color={'textSecondary'}>{t('account.network')}</Text>
      {chain &&
          <Text weight={500}>{ChainName[chain.id]}</Text>
      }
    </Box>
    <Box margin={{ top: '16px' }} pad={{ top: '8px' }} border={{ side: 'top' }}>
      <Box direction={'row'} pad={{ top: '8px' }} gap={'16px'} align={'center'} onClick={onCopyAddress}>
        <CopyImg width={'22px'} height={'22px'} style={{ color: '#999AAA' }} />
        {!isCopied &&
            <Text>{t('account.copy_address')}</Text>
        }
        {isCopied &&
            <Text>{t('account.copied')}</Text>
        }
      </Box>
      <Box direction={'row'} gap={'16px'} align={'center'} margin={{ top: '8px' }} onClick={viewOnExplorer}>
        <LinkImg width={'22px'} height={'22px'} style={{ color: '#999AAA' }} />
        <Text>{t('account.view_on_explorer')}</Text>
      </Box>
    </Box>
  </WidgetContainer>

  const items: MenuProps['items'] = [{
    key: 'account',
    label: dropdownContent
  }]

  return <Dropdown
    menu={{ items }}
    open={isOpened}
    trigger={['click']}
    overlayClassName={'antd_dropdown_overlay'}
  >
    <ConnectedButtonContainer
      onClick={(e) => {
        setOpened(!isOpened)
        // const button = document.querySelectorAll('.connected_button')
        // if(button) {
        //   if(button[0].contains(e.currentTarget)) {
        //   }
        // }
      }}
    >
      <Box direction={'row'} gap={'8px'} align={'center'}>
        {connector?.name === 'MetaMask' &&
            <Box>
                <MetamaskLogo width={'16px'} height={'16px'} />
            </Box>
        }
        {connector?.name === 'WalletConnect' &&
            <Box>
                <WalletConnectLogo width={'16px'} height={'16px'} />
            </Box>
        }
        <Box>
          {(address) &&
              <Text color={'text'} weight={500}>
                {truncateEthAddress(address || '')}
              </Text>
          }
        </Box>
        <Box>
          <Arrow direction={isOpened ? 'up' : 'down'} width={'10px'} />
        </Box>
      </Box>
    </ConnectedButtonContainer>
  </Dropdown>
}

const ConnectButton = styled(Button)`
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
    transition: background-color 1s;
    background: linear-gradient(150deg, #53A986 10%, transparent), #97C255;

    &:hover, &:focus {
        background-color: #53A986;
        color: #fff;
    }
`

const NotConnectedButton = (props: AccountButtonProps) => {
  const { t } = useTranslation()

  return <ConnectButton onClick={() => props.setModalOpened(true)}>
    <Text weight={500} color={'white'}>{t('wallet.connect_wallet')}</Text>
  </ConnectButton>
}

export const ConnectWallet = () => {
  const [isModalOpened, setModalOpened] = useState(false)
  const { isConnected } = useAccount()

  return <Box>
    {isConnected && <ConnectedButton />}
    {!isConnected && <NotConnectedButton isModalOpened={isModalOpened} setModalOpened={setModalOpened} />}
    <ConnectModal isOpened={!isConnected && isModalOpened} setShow={setModalOpened} />
  </Box>
}
