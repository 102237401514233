import React, {createContext, useContext, useState, PropsWithChildren, useEffect} from 'react';
import {useAccount} from "wagmi";
import {FutureInfo, VaultInfo} from "../types";
import useIsTabActive from "../hooks/useIsTabActive";
import usePoller from "../hooks/usePoller";
import {getOraclePackages} from "../api/oracleService";
import {
  getAllVaultAddresses,
  getMaxWithdraw,
  getTotalAssets,
  getUnderlyingAddress,
  getVaultIdByAddress,
  getVaultKey,
  getVaultName,
  getVaultTotalSupply,
  getUserPendingWithdrawalsAmount,
  RhoVaultKey,
  getIsVaultUser,
} from "../api/vault";
import {BigNumber} from "ethers";
import {getActiveMarketsInfo} from "../api/viewContract";
import config from '../config'
import {toast} from "react-toastify";
import {getVaultPortfolio, VaultPortfolio} from "../api/vault-api";

export interface VaultsData {
  isInitialLoading: boolean
  isVaultUser: boolean
  totalEarned: BigNumber
  vault?: VaultInfo
  vaults: VaultInfo[]
  vaultPortfolio?: VaultPortfolio
  refetch: () => Promise<VaultInfo | undefined>
}

const getInitialState = (): VaultsData => {
  return {
    isInitialLoading: false,
    isVaultUser: false,
    totalEarned: zero,
    vault: undefined,
    vaultPortfolio: undefined,
    vaults: [],
    refetch: async () => {
      return undefined
    },
  }
}

const zero = BigNumber.from(0)

const initialState = getInitialState()
const UserDataContext = createContext(initialState);

export const useVault = () => useContext(UserDataContext);

export const VaultDataProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { address: userAddress } = useAccount()
  const isTabActive = useIsTabActive()

  const [isInitialLoading, setInitialLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isVaultUser, setIsVaultUser] = useState(false)
  const [vaults, setVaults] = useState<VaultInfo[]>([])
  const [vaultPortfolio, setVaultPortfolio] = useState<VaultPortfolio>()
  const [totalEarned, setTotalEarned] = useState(zero)

  useEffect(() => {
    bootstrap()
  }, [userAddress]);

  usePoller(() => {
    const pageUrl = window.location.href || ''
    const isEarnPage = pageUrl.includes('earn')
    if(isTabActive && userAddress && !isFetching && isEarnPage) {
      refetch()
    }
  }, 5_000)

  const getDataFromVaultsAPI = async (vaults: VaultInfo[] = []) => {
    if(userAddress) {
      try {
        const newVaultPortfolio = await getVaultPortfolio({
          userAddress
        })

        if(!vaultPortfolio || (vaultPortfolio && vaultPortfolio.netBalance !== newVaultPortfolio.netBalance)) {
          const allocatorVault = vaults.find(vault => vault.id === config.vaultAllocatorId)
          if(allocatorVault) {
            const netBalance = BigNumber.from(newVaultPortfolio.netBalance || zero)
            const maxWithdraw = allocatorVault?.maxWithdraw || zero
            const earned = maxWithdraw.sub(netBalance)
            console.log('[VaultDataProvider] total earned updated:', earned.toString())
            setTotalEarned(earned)
          }
        }

        setVaultPortfolio(newVaultPortfolio)
      } catch (e) {
        console.error('Failed to load vault portfolio:', e)
      }
    } else {
      setVaultPortfolio(undefined)
      setTotalEarned(zero)
    }
  }

  const refetch = async () => {
    let vaultsUpdated: VaultInfo[] = []
    let allocatorVault: VaultInfo | undefined = undefined
    setIsFetching(true)
    try {
      vaultsUpdated = await Promise.all(vaults.map(async vault => {
        if(vault.id === config.vaultAllocatorId) {
          const packages = await getOraclePackages()

          const maxWithdraw = userAddress
            ? await getMaxWithdraw(vault.address, userAddress, packages)
            : BigNumber.from(0)
          const userPendingWithdrawalsAmount = userAddress
            ? await getUserPendingWithdrawalsAmount(vault.address, userAddress)
            : BigNumber.from(0)
          const totalAssets = await getTotalAssets(vault.address, packages)

          const newVault = {
            ...vault,
            maxWithdraw,
            totalAssets,
            userPendingWithdrawalsAmount
          }
          allocatorVault = newVault
          return newVault
        }
        return vault
      }))
      setVaults(vaultsUpdated)
      await getDataFromVaultsAPI(vaultsUpdated)
      return allocatorVault
    } catch(e) {
      console.error('[useVault] Failed to fetch vault user balance:', e)
    } finally {
      setIsFetching(false)
    }
  }

  const bootstrap = async () => {
    let vaultsData: VaultInfo[] = []
    try {
      setInitialLoading(true)
      setVaults([])
      setVaultPortfolio(undefined)
      setTotalEarned(zero)

      let isUserWhitelisted = false
      const addresses = await getAllVaultAddresses()
      const markets = await getActiveMarketsInfo()

      vaultsData = await Promise.all(addresses.map(async (vaultAddress) => {
        const underlyingAddress = await getUnderlyingAddress(vaultAddress)
        const id = await getVaultIdByAddress(vaultAddress)

        let marketIds: string[] = []
        let futures: FutureInfo[] = []
        let vaultName = ''
        let maxWithdraw = BigNumber.from(0)
        let totalSupply = BigNumber.from(0)
        let totalAssets = BigNumber.from(0)
        let userPendingWithdrawalsAmount = BigNumber.from(0)
        let key: RhoVaultKey = {
          name: '',
          version: 0,
          underlying: ''
        }

        if(id === config.vaultAllocatorId) {
          const packages = await getOraclePackages()

          futures = markets
            .filter(market => marketIds.includes(market.descriptor.id))
            .flatMap(market => market.futures)

          vaultName = await getVaultName(vaultAddress)
          totalSupply = await getVaultTotalSupply(vaultAddress)
          totalAssets = await getTotalAssets(vaultAddress, packages)
          key = await getVaultKey(vaultAddress)
          if(userAddress) {
            maxWithdraw = await getMaxWithdraw(vaultAddress, userAddress, packages)
            userPendingWithdrawalsAmount = await getUserPendingWithdrawalsAmount(vaultAddress, userAddress)
            isUserWhitelisted = await getIsVaultUser(vaultAddress, userAddress)
          }
        }
        return {
          id,
          address: vaultAddress,
          name: vaultName,
          underlyingName: 'USDT',
          underlyingAddress,
          underlyingDecimals: 6,
          marketIds,
          futures,
          maxWithdraw,
          totalSupply,
          totalAssets,
          userPendingWithdrawalsAmount,
          key
        }
      }))
      console.log('Vaults data:', vaultsData)
      setVaults(vaultsData)
      setIsVaultUser(isUserWhitelisted)
    } catch (e) {
      console.error('Bootstrap failed:', e)
      toast.error(`Failed to load vault info: ${(e as Error).message}`)
    } finally {
      setInitialLoading(false)
    }

    await getDataFromVaultsAPI(vaultsData)
  }

  return <UserDataContext.Provider value={{
    isInitialLoading,
    isVaultUser,
    totalEarned,
    vaults,
    vault: vaults.find(item => item.id === config.vaultAllocatorId),
    vaultPortfolio,
    refetch,
  }}>
    {children}
  </UserDataContext.Provider>
};
