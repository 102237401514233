import React, {useEffect, useRef, useState} from 'react'
import {Dropdown, MenuProps, DatePicker} from 'antd'
import {Box, Text} from "grommet";
import styled from "styled-components";
import dayjs from 'dayjs';
import {Arrow} from "./Icon";
import moment from "moment";
import useOnClickOutside from "../hooks/useOnClickOutside";
import {RangePickerProps} from "antd/es/date-picker";

const { RangePicker } = DatePicker;

export interface SelectProps {
  placeholder: React.ReactNode
  items: MenuProps['items']
  onUpdate: (activeKeys: string[]) => void
  onClick?: (id: string) => void
  width?: string
  height?: string
}

export const SelectWrapper = styled(Box)`
  user-select: none;
  height: auto;
`

export const SelectButton = styled(Box)`
  padding: 20px 12px;
  border-radius: 6px;
  background: #19191A;
  height: ${({ height }) => height || '38px'};
`

export const ArrowWrapper = styled(Box)`
  padding-right: 4px;
`

export const Select = (props: SelectProps) => {
  const { items = [], height, width  } = props
  const [ isOpened, setOpened] = useState(false)

  const onOpenChange = (value: boolean, info: { source: string }) => {
    if(info.source === 'trigger') {
      setOpened(value)
    }
  }

  const itemsUpdated: any = items.map((item) => {
    return {
      ...item,
      onClick: () => {
        if(props.onClick) {
          setOpened(false)
          props.onClick((item && item.key) ? (item.key as string) : '')
        }
      }
    }
  })

  return <SelectWrapper>
    <Dropdown
      open={isOpened}
      menu={{ items: itemsUpdated }}
      trigger={['click']}
      onOpenChange={onOpenChange}
    >
      <SelectButton
        height={height}
        width={width}
        direction={'row'}
        justify={'center'}
        align={'center'}
        gap={'8px'}
      >
        {props.placeholder}
        <ArrowWrapper>
          <Arrow direction={isOpened ? 'up' : 'down'} />
        </ArrowWrapper>
      </SelectButton>
    </Dropdown>
  </SelectWrapper>
}

export interface SelectDateProps {
  filterName: string // TODO: make it optional
  placeholder: React.ReactNode
  values: number[]
  onUpdate: (dates: number[]) => void
  icon?: React.ReactNode
}

export const SelectDate = (props: SelectDateProps) => {
  const [isOpened, setOpened] = useState(false)
  const [selectedDates, setSelectedDates] = useState<number[]>(props.values)
  const ref = useRef<any>();

  useOnClickOutside(ref, () => setOpened(false));

  const getButtonText = () => {
    if(selectedDates.length !== 2) {
      return props.placeholder
    }
    const formattedFrom = moment(selectedDates[0] * 1000).format('MMM D')
    const formattedTo = moment(selectedDates[1] * 1000).format('MMM D')
    if(moment(selectedDates[0] * 1000).startOf('day').unix()
      === moment(selectedDates[1] * 1000).startOf('day').unix()
    ) {
      return <Text weight={500}>{formattedFrom}</Text>
    }
    return <Text weight={500}>{formattedFrom} - {formattedTo}</Text>

  }

  const clearDates = () => {
    setSelectedDates([])
    props.onUpdate([])
  }

  const onChange = (dates: any) => {
    if(dates.length === 2) {
      const timestampFrom = moment(dates[0].$d).startOf('day').unix()
      const timestampTo = moment(dates[1].$d).endOf('day').unix()
      const timestamps = [timestampFrom, timestampTo]
      setSelectedDates(timestamps)
      props.onUpdate(timestamps)
      setOpened(false)
    } else {
      clearDates()
    }
  }

  const rangePickerProps: RangePickerProps = {}
  if(props.values.length === 2) {
    rangePickerProps.value = [dayjs(props.values[0] * 1000), dayjs(props.values[1] * 1000)]
  }

  return <Box
    className={'antd_daterange_container'}
    style={{ position: 'relative', userSelect: 'none' }}
  >
    <SelectButton
      height={'38px'}
      direction={'row'}
      justify={'center'}
      align={'center'}
      gap={'8px'}
      onClick={() => setOpened(!isOpened)}
    >
      {getButtonText()}
      {props.icon &&
        props.icon
      }
    </SelectButton>
    <Box
      ref={ref}
      className={`${props.filterName}_container`}
      style={{ position: 'absolute', right: '-300px' }}
    />
    {isOpened &&
        <RangePicker
          open={isOpened}
          {...rangePickerProps}
          renderExtraFooter={() => {
            if(selectedDates.length !== 2) {
              return null
            }
            return <Box pad={'8px'}>
              <Text
                weight={500}
                size={'16px'}
                color={'#4852FF'}
                onClick={clearDates}
                style={{ cursor: 'pointer' }}
              >
                Clear dates
              </Text>
            </Box>
          }}
          getPopupContainer={() => document.querySelector(`.${props.filterName}_container`) || document.body}
          onChange={onChange} />
    }
  </Box>
}

export interface CompactSelectProps {
  selectedOption: string;
  options: string[]
  onSelect: (option: string) => void
}

export const CompactSelect = (props: CompactSelectProps) => {
  const { options, selectedOption, onSelect } = props

  const [isOpened, setOpened] = useState(false)
  const anotherOptions = options.filter(item => item !== selectedOption)

  return  <Box
    className={'rho_compact_select'}
    style={{ position: 'relative', minWidth: '50px', userSelect: 'none' }}
  >
    <Box
      direction={'row'}
      justify={'between'}
      align={'center'}
      gap={'4px'}
      pad={'2px 4px'}
      round={'4px'}
      border={{
        color: '#3D3F5C'
      }}
      onClick={() => setOpened(!isOpened)}
    >
      <Text size={'12px'}>{selectedOption}</Text>
      <Arrow direction={isOpened ? 'up' : 'down'} width={'8px'} />
    </Box>
    {isOpened &&
        <Box width={'100%'} style={{ position: 'absolute', top: '100%' }}>
          {anotherOptions.map(item => {
            return <Box
              key={item}
              round={'4px'}
              pad={'4px 4px'}
              background={'badgeBackground'}
              onClick={() => {
                props.onSelect(item)
                setOpened(false)
              }}
            >
              <Text size={'12px'}>{item}</Text>
            </Box>
          })}
        </Box>
    }
  </Box>
}
