import { Routes, Route, Navigate } from 'react-router-dom';
import {MarketsPage} from "./pages/markets";
import {AppLayout} from "./components/layout";
import {PortfolioPage} from "./pages/portfolio";
import {LiquidityProvision} from "./pages/liquidity-provision";
import {History} from "./pages/history";
import {PageNotFound} from "./pages/404";
import {Trade} from "./pages/trade";
import {useMediaQuery} from "react-responsive";
import {MobileNotSupported} from "./components/layout/MobileNotSupported";
import {RestrictedPersons} from "./pages/restricted-persons";
import {ModalsHOC} from "./components/modals/ModalsHOC";
import {EarnPage} from "./pages/earn";

export const AppRoutes = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  if(isMobile) {
    return <MobileNotSupported />
  }

  return <Routes>
    <Route element={
      <ModalsHOC>
        <AppLayout />
      </ModalsHOC>
    }>
      <Route path={'/'} element={<Navigate to={'trade'} />} />
      <Route path={'/restricted'} element={<Navigate to={'/trade'} />} />
      <Route path={'/trade/:futureAlias'} element={<Trade />} />
      <Route path={'/trade'} element={<Trade />} />
      <Route
        path={'/trade/liquidity/:futureAlias'}
        element={<LiquidityProvision />}
      />
      <Route path={'/markets'} element={<MarketsPage />} />
      <Route path="markets">
        <Route index element={<MarketsPage />} />
      </Route>
      <Route path="portfolio" element={<PortfolioPage />}>
        <Route path=":marketAlias" element={<PortfolioPage />} />
      </Route>
      <Route path="history" element={<History />} />
      <Route path="earn" element={<EarnPage />} />
      <Route path={'restricted-persons'} element={<RestrictedPersons />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Route>
  </Routes>
}
