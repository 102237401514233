import React, {useMemo} from 'react'
import { Box, Text } from 'grommet'
import {getIconByName, InfoBadge, Number, PrimaryButton, QuestionMark, WidgetContainer} from "../../../components";
import tooltips from "../../../utils/tooltips";
import {PortfolioProps} from "../common";
import {BigNumber} from "ethers";
import {RiskDirection} from "../../../types";
import {bnToDecimal, formatNumber, getColorByValue, getDirectionAlias, getLeverage, numberToBN} from "../../../utils";
import {marginTotal} from "../../../utils/mappers";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {useWithdrawableMargin} from "../../../hooks/useWithdrawableMargin";
import styled from "styled-components";
import {PageModal} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {useAccount} from "wagmi";
import Decimal from "decimal.js";
import {useTokenPrice} from "../../../providers/PriceProvider";

const zero = BigNumber.from(0)
const widgetWidth = '50%'

const SmallButton = styled(PrimaryButton)`
    min-width: 100px;
    max-width: 100px;
    width: 100px;
`

export const BadgesPanel = (props: PortfolioProps) => {
  const { market, marketPortfolio, marketsWithdrawableMargin } = props

  const { isConnected } = useAccount()
  const navigate = useNavigate()
  const { ethereum: ethPrice, tetherPrice } = useTokenPrice()
  const { marketsUserData, markets, accountTotalMarginUSD } = useProtocolData()

  const withdrawableMargin = useMemo(() => {
    if(market !== 'all') {
      const data = marketsWithdrawableMargin.find(item => item.marketId === market?.descriptor.id)
      if(data) {
        return data.withdrawableMargin
      }
    }
    return zero
  }, [market])

  const withdrawableMarginUSD = useMemo(() => {
    return marketsWithdrawableMargin.reduce((acc, item) => {
      const { withdrawableMargin } = item
      let underlyingName = ''
      let underlyingDecimals = 6
      let tokenPrice = 0
      const marketData = markets.find(market => market.descriptor.id === item.marketId)
      if(marketData) {
        underlyingName = marketData.descriptor.underlyingName
        underlyingDecimals = marketData.descriptor.underlyingDecimals
      }
      if(ethPrice && underlyingName.toLowerCase().endsWith('eth')) {
        tokenPrice = ethPrice
      } else if(tetherPrice && underlyingName.toLowerCase().includes('usd')) {
        tokenPrice = tetherPrice
      }
      const withdrawableMarginDecimal = bnToDecimal(withdrawableMargin, underlyingDecimals).toNumber()
      return acc += withdrawableMarginDecimal * tokenPrice
    }, 0)
  }, [market, marketsWithdrawableMargin, ethPrice, tetherPrice])

  const riskDirection = useMemo(() => {
    if(marketPortfolio) {
      const { marginState } = marketPortfolio
      const floatTokenSum = marketPortfolio.futureOpenPositions.reduce((acc, nextItem) => {
        const { tokensPair: { floatTokenAmount } } = nextItem
        return acc.add(floatTokenAmount)
      }, zero)

      return floatTokenSum.isZero()
        ? RiskDirection.none
        : marginState.riskDirection === 0
          ? RiskDirection.receiver
          : RiskDirection.payer
    }
    return RiskDirection.none
  }, [marketPortfolio])

  const currentMargin = marketPortfolio ? marginTotal(marketPortfolio.marginState.margin) : zero

  const profitAndLoss = useMemo(() => {
    if(marketPortfolio) {
      const { descriptor: { underlyingDecimals, id: marketId } } = marketPortfolio
      const marketData = marketsUserData.find(item => item.marketId === marketId)
      if(marketData) {
        return numberToBN(marketData.profitAndLoss, underlyingDecimals)
      }
    }
    return zero
  }, [marketPortfolio, marketsUserData])

  const profitAndLossTotalUSD = marketsUserData.reduce((acc, item) => {
    const pnl = item.profitAndLoss
    let underlyingName = ''
    let tokenPrice = 0
    const marketData = markets.find(market => market.descriptor.id === item.marketId)
    if(marketData) {
      underlyingName = marketData.descriptor.underlyingName
    }
    if(ethPrice && underlyingName.toLowerCase().endsWith('eth')) {
      tokenPrice = ethPrice
    } else if(tetherPrice && underlyingName.toLowerCase().includes('usd')) {
      tokenPrice = tetherPrice
    }
    const pnlUSD = pnl * tokenPrice
    return acc += pnlUSD
  }, 0)

  const currentLeverage = useMemo(() => {
    let value = getLeverage({
      market: market !== 'all' ? market : undefined,
      marketPortfolio
    })
    if(value.leverage > 1) {
      value.leverage = +(value.leverage.toFixed(0))
    } else {
      value.leverage = +(value.leverage.toFixed(1))
    }
    return value
  }, [market, marketPortfolio])

  const marginUsage = useMemo(() => {
    if(market === 'all' && accountTotalMarginUSD > 0 && withdrawableMarginUSD) {
      return ((1 - (withdrawableMarginUSD / accountTotalMarginUSD)) * 100).toFixed(2)
    } else if(marketPortfolio && currentMargin.gt(0)) {
      const { descriptor: { underlyingDecimals} } = marketPortfolio
      return new Decimal(1).minus(
          bnToDecimal(withdrawableMargin, underlyingDecimals)
          .div(bnToDecimal(currentMargin, underlyingDecimals))
        )
        .mul(100)
        .toDecimalPlaces(2)
        .toString()
    }
    return null
  }, [market, withdrawableMargin, currentMargin, marketPortfolio, withdrawableMarginUSD, accountTotalMarginUSD])

  const commonButtonsProps = {
    disabled: !props.market || !isConnected || market === 'all',
    size: '16px',
    style: { height: '38px', minHeight: '38px', width: '100px', maxWidth: '100px', padding: '0' }
  }

  const openManageMargin = (marketId: string, type: 'deposit' | 'withdraw') => {
    navigate(`?modal=${PageModal.margin}&type=${type}&marketId=${marketId}`)
  }

  return <Box>
    <WidgetContainer gap={'16px'} style={{
      background: '#1B1B1D',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }}>
      <Box direction={'row'} gap={'16px'}>
        <InfoBadge
          width={widgetWidth}
          icon={getIconByName('direction').icon}
          title={
            <Text
              size={'18px'}
              color={getColorByValue(riskDirection)}>
              {market !== 'all' ? getDirectionAlias(riskDirection) : '-'}
            </Text>
          }
          description={'Net risk direction'}
          tooltip={tooltips.riskDirection}
        />
        <InfoBadge
          width={widgetWidth}
          icon={getIconByName('pulse').icon}
          title={marketPortfolio?.marginState ? <Number
            value={profitAndLoss}
            decimals={marketPortfolio.descriptor.underlyingDecimals}
            name={marketPortfolio.descriptor.underlyingName}
            showName={true}
            fontColor={getColorByValue(profitAndLoss)}
            fontSize={'18px'}
          />
            : market === 'all'
              ? <Text size={'18px'} color={getColorByValue(profitAndLossTotalUSD)}>
                  ${formatNumber(profitAndLossTotalUSD)}
                </Text>
              : '-'}
          description={'Net P&L'}
          tooltip={tooltips.profitAndLoss}
        />
        <InfoBadge
          width={widgetWidth}
          icon={getIconByName('collection').icon}
          title={marketPortfolio?.marginState
            ? <Number
                value={currentMargin}
                decimals={marketPortfolio.descriptor.underlyingDecimals}
                name={marketPortfolio.descriptor.underlyingName}
                showName={true}
                fontColor={getColorByValue(currentMargin)}
                fontSize={'18px'}
              />
            : market === 'all'
                ? <Text size={'18px'} color={getColorByValue(accountTotalMarginUSD)}>
                    ${formatNumber(accountTotalMarginUSD)}
                  </Text>
                : '-'
          }
          description={'Current margin'}
          tooltip={tooltips.currentMargin}
        />
      </Box>
      <Box direction={'row'} gap={'16px'}>
        <InfoBadge
          width={widgetWidth}
          icon={getIconByName('funnel').icon}
          title={marketPortfolio?.marginState ? `x${currentLeverage.leverage}` : '-'}
          description={'Leverage'}
          tooltip={tooltips.marketLeverage}
        />
        <InfoBadge
          width={widgetWidth}
          icon={getIconByName('settings').icon}
          title={marketPortfolio?.marginState ? <Number
            value={marketPortfolio.marginState.dv01}
            decimals={marketPortfolio.descriptor.underlyingDecimals}
            name={marketPortfolio.descriptor.underlyingName}
            showName={true}
            fontSize={'18px'}
          /> : '-'}
          description={'Net DV01'}
          tooltip={tooltips.netDV01}
        />
        <InfoBadge
          width={widgetWidth}
          icon={getIconByName('chartPie').icon}
          title={
            (marginUsage !== null && (marketPortfolio?.marginState || market === 'all'))
              ? `${marginUsage}%`
              : '-'
          }
          description={'Margin usage'}
          tooltip={tooltips.marginUsage}
        />
      </Box>
    </WidgetContainer>
    <WidgetContainer
      direction={'row'}
      align={'center'}
      justify={'between'}
      style={{
        background: '#1F1F23',
        padding: '19px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Box>
        {market === 'all'
          ? <Text size={'15px'} color={'textHeader'}>
            {formatNumber(withdrawableMarginUSD, 'USD', false, 2)}
          </Text>
          : marketPortfolio
          ? <Number
              value={withdrawableMargin}
              decimals={marketPortfolio.descriptor.underlyingDecimals}
              name={marketPortfolio.descriptor.underlyingName}
              showName={true}
              fontSize={'15px'}
              fontColor={'textHeader'}
            />
          : <Box>
              <Text style={{ lineHeight: '18px' }}>-</Text>
            </Box>
        }
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>Avl. margin</Text>
          <QuestionMark tooltipId={'av_margin'} tooltipText={tooltips.availableMargin} />
        </Box>
      </Box>
      <Box width={'1px'} height={'100%'} background={'#4F5064'} />
      <Box direction={'row'} gap={'16px'}>
        <SmallButton
          {...commonButtonsProps}
          text={'Deposit'}
          onClick={() => {
            if(market && market !== 'all') {
              openManageMargin(market.descriptor.id, 'deposit')
            }
          }}
        />
        <SmallButton
          {...commonButtonsProps}
          disabled={commonButtonsProps.disabled || withdrawableMargin.eq(0)}
          viewType={'secondary'}
          text={'Transfer'}
          onClick={() => {
            if(market && market !== 'all') {
              navigate(`?modal=${PageModal.transfer}&marketId=${market.descriptor.id}`)
            }
          }}
        />
        <SmallButton
          {...commonButtonsProps}
          disabled={commonButtonsProps.disabled || withdrawableMargin.eq(0)}
          viewType={'secondary'}
          text={'Withdraw'}
          onClick={() => {
            if(market && market !== 'all') {
              openManageMargin(market.descriptor.id, 'withdraw')
            }
          }}
        />
      </Box>
    </WidgetContainer>
  </Box>
}
