import React from 'react'
import {TradeProps} from "../common";
import {Box} from "grommet";
import {TradeRequest} from "../trade-request";
import {TradingViewChart} from "../trading-view";
import {MarketTradeHeader} from "../market-header";
import {WidgetsBox} from "../../../components";

const gap = '16px'

export const IPadProView = (formProps: TradeProps) => {
  const { modalId } = formProps

  return <Box margin={{ top: '24px', bottom: '32px' }} gap={gap}>
    <Box gap={gap}>
      <MarketTradeHeader {...formProps} viewType={'small'} />
      <WidgetsBox gap={gap} overlay={modalId === 'market'}>
        <Box height={'400px'}>
          <TradingViewChart {...formProps} />
        </Box>
        <TradeRequest {...formProps} />
      </WidgetsBox>
    </Box>
  </Box>
}
